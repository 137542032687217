import React from 'react'
import { Provider } from 'react-redux'
import I18n from "redux-i18n"
import { translations } from "./translations/translation";
import { connect } from "react-redux";
import { SnackbarProvider } from "notistack";

const ProviderSetup = ({ children, store, lang }) => (
    <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
            <I18n translations={translations} initialLang={lang}>
                {children}
            </I18n>
        </SnackbarProvider>
    </Provider>
);

const mapState = state => {
    return { lang: state.preference.language }
};

export default connect(mapState)(ProviderSetup)
