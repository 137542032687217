import {
  createSlice
} from 'redux-starter-kit'
import {
  getStateFromCookies
} from 'redux-cookies-middleware';
import _ from 'lodash';
import {
  login as seekerLogin,
  tokenLogin as seekerLoginViaToken,
  logout as seekerLogout,
  loginViaUrlApi,
} from '../apis/jobseeker';
import {
  login as recruiterLogin,
  logout as recruiterLogout
} from '../apis/recruiter';
import {
  loginApi as adminLogin,
  logoutApi as adminLogout,
  actAsApi,
} from '../apis/admin';
import {
  checkLoginSso
} from '../apis/client'
import {
  ROLE,
  STATUS,
  LOGIN_METHOD,
} from '../constants/constants';
import size from 'lodash/size'
import get from 'lodash/get'

// state to persist in cookies
const cookiePathMapping = {
  'username': {
    name: 'username'
  },
  'rememberMe': {
    name: 'remember_me'
  },
  'serial': {
    name: 'serial'
  },
  'validationCode': {
    name: 'validation_code'
  },
  'token': {
    name: 'token'
  },
  "s3Token": {
    name: 's3Token'
  },
  "s3IdentityId": {
    name: 's3IdentityId'
  },
  "thirdPartyAccessToken": {
    name: "thirdPartyAccessToken"
  },
  "neufastSsoToken": {
    name: "neufastSsoToken"
  },
  adminToken: { name: 'adminToken' },
  loginMethod: { name: 'loginMethod' },
  jobseekerCode: { name: 'jobseekerCode' },
  authenticationErrorCode: { name: 'authenticationErrorCode' },
  'extraInfo.code': { name: 'extraInfo.code' },
  'extraInfo.redirectUri': { name: 'extraInfo.redirectUri' },
  'extraInfo.datafeedUrl': { name: 'extraInfo.datafeedUrl' },
};

let initialState = {
  username: '',
  token: '',
  rememberMe: false,
  role: ROLE.guest,
  serial: '',
  validationCode: '',
  authenticationInProgress: false,
  logoutInProgress: false,
  authenticationError: '',

  adminToken: null,
  actingAs: false,

  s3Token: null,
  s3IdentityId: null,

  loginMethod: LOGIN_METHOD.normal,
  jobseekerCode: null,
  authenticationErrorCode: 0,
  extraInfo: {
    code: '',
    redirectUri: '',
    datafeedUrl: '',
  },
  triggerRefreshTokenUpdate: false,
  thirdPartyAccessToken: null,
  neufastSsoToken: null,
};
initialState = {
  ...initialState,
  ..._.omitBy(getStateFromCookies({}, cookiePathMapping), _.isNil)
};

const authenticationSlice = createSlice({
  slice: 'authenticated',
  initialState: initialState,
  reducers: {
    toggleRememberMe(state, action) {
      state.rememberMe = !state.rememberMe;
    },
    onRoleChange(state, action){
      state.role = action.payload;
    },
    onLoginStarted(state, action) {
      state.username = action.payload.username;
      state.rememberMe = action.payload.rememberMe;
      state.validationCode = null;
      state.authenticationInProgress = true;
      state.loginMethod = LOGIN_METHOD.normal
      state.extraInfo = {
        code: '',
        redirectUri: '',
        datafeedUrl: '',
      }
    },
    onLoginSuccess(state, action) {
      const authenticationResponse = action.payload;
      state.token = authenticationResponse.token;
      state.s3Token = authenticationResponse?.s3_token;
      state.s3IdentityId = authenticationResponse?.s3_identityId;
      state.validationCode = authenticationResponse.message ? authenticationResponse.message : null;
      if(authenticationResponse.role){
        state.role = authenticationResponse?.role;
      }
      state.authenticationInProgress = false;
    },
    onUpdatingSsoTokens(state, action){
      const authenticationResponse = action.payload;
      state.thirdPartyAccessToken = authenticationResponse.access_token;
      state.neufastSsoToken = authenticationResponse.sso_token;
    },
    activateRefreshTokenUpdateHook(state, action){
      state.triggerRefreshTokenUpdate = true
    },
    disableRefreshTokenUpdateHook(state, action){
      state.triggerRefreshTokenUpdate = false
    },
    onLoginFailed(state, action) {
      const authenticationResponse = action.payload;
      state.authenticationError = authenticationResponse.message;
      state.authenticationInProgress = false;
    },
    onLogout(state, action) {
      state.token = null;
      state.username = null;
      state.validationCode = null;
      state.rememberMe = false;
      state.logoutInProgress = false;
      state.neufastSsoToken = null;
      state.thirdPartyAccessToken = null;
      state.s3IdentityId = null;
      state.s3Token = null;
      state.extraInfo = {
        code: '',
        redirectUri: '',
        datafeedUrl: '',
      }
      sessionStorage.removeItem('audioCheckCompletedOnce')
    },
    onLogoutStart(state, action) {
      state.logoutInProgress = true;
    },

    onActingAs( state, action ) {
      state.actingAs = true
      state.loginMethod = LOGIN_METHOD.actingAs
    },
    onActingAsSuccess( state, action ) {
      state.actingAs = false
      console.debug( { action } )
      if( size( action.payload.token ) === 0 ) {
        state.token = state.adminToken
        state.adminToken = null
      } else {
        state.adminToken = state.token
        state.token = action.payload.token
      }
    },
    onActingAsFailed( state, action ) {
      state.actingAs = false
    },

    onLoggingInViaUrl( state, action ) {
      state.authenticationInProgress = true
      state.jobseekerCode = get( action, 'payload.jobseekerCode' )
      state.authenticationErrorCode = 0
      state.token = null
      state.extraInfo = {
        code: '',
        redirectUri: '',
        datafeedUrl: '',
      }
      state.loginMethod = LOGIN_METHOD.viaUrl
    },
    onLoggingInViaUrlSucceeded( state, action ) {
      state.authenticationInProgress = false
      state.authenticationErrorCode = 0
      state.token = get( action, 'payload.token' )
      state.s3Token = get( action, 'payload.s3_token' )
      state.s3IdentityId = get( action, 'payload.s3_identityId' )
      state.extraInfo = {
        code: get( action, 'payload.code' ),
        redirectUri: get( action, 'payload.redirect_uri' ),
        datafeedUrl: get( action, 'payload.datafeed_url' ),
      }
    },
    onLoggingInViaUrlFailed( state, action ) {
      state.authenticationInProgress = false
      state.authenticationErrorCode = get( action, 'payload.error_code' )
      state.extraInfo = {
        code: get( action, 'payload.code' ),
        redirectUri: get( action, 'payload.redirect_uri' ),
        datafeedUrl: get( action, 'payload.datafeed_url' ),
      }
    },

    setAuthenticationErrorCode( state, action ) {
      console.debug( { action } )
      state.authenticationErrorCode = get( action, 'payload' )
    },
  }
});

export const {
  onRoleChange,
  onLoginStarted,
  onLoginSuccess,
  onUpdatingSsoTokens,
  activateRefreshTokenUpdateHook,
  disableRefreshTokenUpdateHook,
  onLoginFailed,
  toggleRememberMe,
  onLogout,
  onLogoutStart,
  onActingAs,
  onActingAsSuccess,
  onActingAsFailed,
  onLoggingInViaUrl,
  onLoggingInViaUrlSucceeded,
  onLoggingInViaUrlFailed,
  setAuthenticationErrorCode,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;

export const login = (email, password, role, rememberMe, validationCode,LoginID = "") => async dispatch => {
  try {
    let loginResponse = null;
    dispatch(onLoginStarted({
      username: email,
      rememberMe: rememberMe,
    }));
    if (role === ROLE.jobseeker) {
      
      loginResponse = await seekerLogin(LoginID, password)
    } else if (role === ROLE.recruiter) {
      const rememberMeToken = validationCode ? validationCode : '';
      loginResponse = await recruiterLogin(email, password, rememberMeToken);
    } else if (role === ROLE.admin) {
      loginResponse = await adminLogin(email, password)
    }
    console.log(loginResponse,"test")
    if (loginResponse) {
      loginResponse = loginResponse.obj;
      console.log("loginResponse: in authentication: ", loginResponse);
      if (loginResponse.status === STATUS.success) {
        dispatch(onLoginSuccess(loginResponse));
        return {
          token: loginResponse.token
        };
      } else {
        dispatch(onLoginFailed(loginResponse));
        return {
          message: loginResponse.message
        };
      }
    }
    return {
      message: "Login Error"
    };
  } catch (err) {
    dispatch(onLoginFailed({
      message: err.toString()
    }));
    return {
      message: "Interview Portal is undergoing maintenance work. Please try again later after 30 min."
    };
  }
};
export const tokenLogin = (email, token, role, rememberMe, urlHash) => async dispatch => {
  try{
    let loginResponse = null;
    dispatch(onLoginStarted({
      username: email,
      rememberMe: rememberMe
    }));
    if (role === ROLE.jobseeker) {
      loginResponse = await seekerLoginViaToken(email, token, urlHash)
    }
    if (loginResponse) {
      loginResponse = loginResponse.obj
      console.log("loginResponse: ", loginResponse)
      if (loginResponse.status === STATUS.success){
        dispatch(onLoginSuccess(loginResponse));
        return {
          token: loginResponse.token
        };
      } else {
        dispatch(onLoginFailed(loginResponse));
        return {
          message: loginResponse.message
        };
      };
    }
  } catch (err) {
    dispatch(onLoginFailed({
      message: err.toString()
    }));
    return {
      message: "Login Error"
    }
  }
}

export const SsoLoginAIA = (urlHash) => async dispatch => {
  try {
    let loginResponse = null;
    dispatch(onLoginStarted({
      username: "",
      rememberMe: ""
    }));
    loginResponse = await checkLoginSso(urlHash)
    if (loginResponse){
      loginResponse = loginResponse.obj
      console.log("loginResponse: ", loginResponse)
      if (loginResponse.status === STATUS.success) {
        console.log("Status is `success`")
        let responseMsg = null
        try {
          responseMsg = loginResponse.message;
        } catch (error) {
          console.error(error)
        }
        console.log("responseMsg: access_token", responseMsg.access_token)
        dispatch(onLoginStarted({
          username: loginResponse.email,
          rememberMe: ""
        }));
        dispatch(onLoginSuccess(loginResponse));
        dispatch(onUpdatingSsoTokens(responseMsg))
        dispatch(activateRefreshTokenUpdateHook())
        return {
          status: "success",
          token: loginResponse.token,
          errorCode: loginResponse.error_code
        };
      } else {
        dispatch(onLoginFailed(loginResponse));
        return {
          status: "failed",
          message: loginResponse.message,
          errorCode: loginResponse.error_code
        };
      }
    }
  } catch (err) {
        dispatch(onLoginFailed({
          message: err.toString()
        }));
        return {
          message: "Login Error"
        }
    }
}
export const updateThirdPartyAccessToken = (newToken) => async dispatch => {
  try{
    dispatch(onUpdatingSsoTokens(newToken))
    return {
      message: "success"
    }
  } catch(err){
    return {
      message: "failed"
    }
  }
}

export const logout = (token, role) => async dispatch => {
  try {
    dispatch(onLogoutStart());
    dispatch(disableRefreshTokenUpdateHook()) // Disable Refresh Token Checking upon login
    if (role === ROLE.jobseeker) {
      await seekerLogout(token)
    } else if (role === ROLE.recruiter) {
      await recruiterLogout(token)
    } else if (role === ROLE.admin) {
      await adminLogout(token)
    }
    return true;
  } catch (err) {console.error(err)} finally {
    dispatch(onLogout());
  }
};

export const softLogout = (token, invalidate_token = false, role) => async dispatch => {
  try {
    dispatch(onLogoutStart());
    dispatch(disableRefreshTokenUpdateHook())
    if (role === ROLE.jobseeker && invalidate_token === true) {
      await seekerLogout(token)
    }
    return true
  } catch (err) {console.error(err)} finally {
  }
};

export const loginViaUrl = jobseekerCode => async dispatch => {
  let ret = false
  try {
    dispatch( onLoggingInViaUrl( { jobseekerCode } ) )
    const response = await loginViaUrlApi( jobseekerCode )
    if( response && response.ok && response.obj ) {
      if( response.obj.error_code === 0 ) {
        dispatch( onLoggingInViaUrlSucceeded( response.obj ) )
        ret = true
      } else {
        dispatch( onLoggingInViaUrlFailed( response.obj ) )
      }
    }
  } catch( error ) {
    dispatch( onLoggingInViaUrlFailed( { message: error.toString() } ) )
  } finally {
    return ret
  }
}

export const actAs = ( token, recruiter='' ) => async dispatch => {
  dispatch( onActingAs() )
  let ret = false
  try {
    const res = await actAsApi( token, recruiter )
    if (res.ok) {
      if (res.obj.error_code === 0) {
        dispatch( onActingAsSuccess( { token: res.obj.token, acting: recruiter !== '' } ) )
        console.debug( { res } )
        ret = true
      } else {
        console.log( `Error with error code (${res.obj.error_code})` )
        dispatch( onActingAsFailed( {
          message: `Error with error code (${res.obj.error_code})`,
        } ) )
      }
    } else {
      console.log("Error with api call (ok)")
      dispatch( onActingAsFailed( {
        message: 'Error with error code (ok)',
      } ) )
    }
  } catch (err) {
    console.log( err )
    dispatch( onActingAsFailed( {
      message: err.toString(),
    } ) )
  }
  return ret
}

