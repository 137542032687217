import {
    createSlice
} from 'redux-starter-kit'

import config from '../config'

import {
    getRecruitersInfoApi,
    getRecruiterInfoApi,
    getUsageApi,
    fozenRecruiterAccApi,
    reactivateRecruiterAccApi,
    deleteRecruiterAccApi,
    getPurchasesList,
    addAllocation,
    createRecruiterApi,
    //    updateRecruiterApi,
    forgetPassword,
    resetPassword,
    getAdminProfileApi,
    setAdminProfileApi,
    adminSendEmailApi,
    edit_admin_passPolicy,
    get_admin_passPolicy_api,
} from '../apis/admin'

import { gets3object } from '../apis/s3access'
import { BUCKETS } from '../constants/constants';

let initialState = {
    adminProfile: {
        email: '',
        companyName: 'Company',
        companyLogo: '',
        CSEmail: ''
    },
    recruiterManaging: [],
    displayList: [],
    currentViewingRecruiter: {},
    usageInfo: [],
    purchase: [],

    //Filter
    stringFilter: '',
    filter: [],
    companyGroupFilter: ['All'],
    weekFilter: '',
    companyFilter: '',

    //In Progress flag
    gettingRecruitersInfoInProgress: false,
    gettingRecruiterInfoInProgress: false,
    gettingUsageInProgress: false,
    creatingRecruiterAccInProgress: false,
    fozenRecruiterAccInProgress: false,
    reactivateRecruiterAccInProgress: false,
    deleteRecruiterAccInProgress: false,
    gettingPurchasesListInProgress: false,
    addingAllocationInProgress: false,
    updatingRecruiter: false,
    resetPasswordInProgress: false,
    sendingForgetPasswordEmailInProgress: false,
    gettingAdminProfile: false,
    settingAdminProfile: false,
    passwordPolicy: {
        "passlen": 12,
        "strength": {
            "upcase": true,
            "lowcase": true,
            "number": true,
            "symbol": true
        },
        "expire": 0,
        "adminResetExpire": false,
        "selfChangePass": true,
        "preventReuse": true
    },
}

const adminSlice = createSlice({
    slice: 'admin',
    initialState: initialState,
    reducers: {
        onGettingRecruitersInfo(state, action) {
            state.gettingRecruitersInfoInProgress = true
        },
        onGettingRecruitersInfoSuccess(state, action) {
            state.gettingRecruitersInfoInProgress = false
            state.recruiterManaging = action.payload
            state.displayList = state.stringFilter === "" ? state.recruiterManaging : state.recruiterManaging.filter((recruiter) => (recruiter.last_name + ' ' + recruiter.first_name).toLocaleLowerCase().includes(state.stringFilter.toLocaleLowerCase()) || recruiter.email.toLocaleLowerCase().includes(state.stringFilter.toLocaleLowerCase()) || recruiter.companyGroup.toLocaleLowerCase().includes(state.stringFilter.toLocaleLowerCase()));

        },
        onGettingRecruitersInfoFailed(state, action) {
            state.gettingRecruitersInfoInProgress = false
        },
        onGettingRecruiterInfo(state, action) {
            state.gettingRecruiterInfoInProgress = true
        },
        onGettingRecruiterInfoSuccess(state, action) {
            state.gettingRecruiterInfoInProgress = false
            state.currentViewingRecruiter = action.payload
        },
        onGettingRecruiterInfoFailed(state, action) {
            state.gettingRecruiterInfoInProgress = false
        },

        onGettingUsage(state, action) {
            state.gettingUsageInProgress = true
        },
        onGettingUsageSuccess(state, action) {
            state.gettingUsageInProgress = false
            state.usageInfo = action.payload.result
            state.weekFilter = action.payload.result[0]._id
        },
        onGettingUsageFailed(state, action) {
            state.gettingUsageInProgress = false
        },

        onCreatingRecruiterAcc(state, action) {
            state.creatingRecruiterAccInProgress = true
        },
        onCreatingRecruiterAccSuccess(state, action) {
            state.creatingRecruiterAccInProgress = false
        },
        onCreatingRecruiterAccFailed(state, action) {
            state.creatingRecruiterAccInProgress = false
        },

        onFozeningRecruiterAcc(state, action) {
            state.fozenRecruiterAccInProgress = true
        },
        onFozeningRecruiterAccSuccess(state, action) {
            state.fozenRecruiterAccInProgress = false
        },
        onFozeningRecruiterAccFailed(state, action) {
            state.fozenRecruiterAccInProgress = false
        },


        onReactivatingRecruiterAcc(state, action) {
            state.reactivateRecruiterAccInProgress = true
        },
        onReactivatingRecruiterAccSuccess(state, action) {
            state.reactivateRecruiterAccInProgress = false
        },
        onReactivatingRecruiterAccFailed(state, action) {
            state.reactivateRecruiterAccInProgress = false
        },

        onDeletingRecruiterAcc(state, action) {
            state.deleteRecruiterAccInProgress = true
        },
        onDeletingRecruiterAccSuccess(state, action) {
            state.deleteRecruiterAccInProgress = false
        },
        onDeletingRecruiterAccFailed(state, action) {
            state.deleteRecruiterAccInProgress = false
        },

        onGettingPurchasesList(state, action) {
            state.gettingPurchasesListInProgress = true
        },
        onGettingPurchasesListSuccess(state, action) {
            state.gettingPurchasesListInProgress = false
            state.purchase = action.payload
        },
        onGettingPurchasesListFailed(state, action) {
            state.gettingPurchasesListInProgress = false
        },

        onAddingAllocation(state, action) {
            state.addingAllocationInProgress = true
        },
        onAddingAllocationSuccess(state, action) {
            state.addingAllocationInProgress = false
        },
        onAddingAllocationFailed(state, action) {
            state.addingAllocationInProgress = false
        },

        //        onUpdatingRecruiter( state, action ) {
        //          state.updatingRecruiter = true
        //        },
        //        onUpdatingRecruiterSuccess( state, action ) {
        //          state.updatingRecruiter = false
        //        },
        //        onUpdatingRecruiterFailed( state, action ) {
        //          state.updatingRecruiter = false
        //        },

        search(state, action) {
            state.stringFilter = action.payload
            state.displayList = state.stringFilter === "" ? state.recruiterManaging : state.recruiterManaging.filter((recruiter) => ((recruiter.last_name + ' ' + recruiter.first_name) && (recruiter.last_name + ' ' + recruiter.first_name).toLocaleLowerCase().includes(state.stringFilter.toLocaleLowerCase())) || (recruiter.email && recruiter.email.toLocaleLowerCase().includes(state.stringFilter.toLocaleLowerCase())) || (recruiter.companyGroup && recruiter.companyGroup.toLocaleLowerCase().includes(state.stringFilter.toLocaleLowerCase())));
        },
        filter(state, action) {
            state.filter = action.payload
        },
        changeCompanyGroupFilter(state, action) {
            state.companyGroupFilter = action.payload
        },
        setCompanyFilter(state, action) {
            state.companyFilter = action.payload
        },
        setWeekFilter(state, action) {
            state.weekFilter = action.payload
        },
        onResetPassword(state, action) {
            state.resetPasswordInProgress = true
        },
        onResetPasswordSuccess(state, action) {
            state.resetPasswordInProgress = false
        },
        onResetPasswordFailed(state, action) {
            state.resetPasswordInProgress = false
        },
        onSendingForgetPasswordEmail(state, action) {
            state.sendingForgetPasswordEmailInProgress = true
        },
        onSendingForgetPasswordEmailSuccess(state, action) {
            state.sendingForgetPasswordEmailInProgress = false
        },
        onSendingForgetPasswordEmailFailed(state, action) {
            state.sendingForgetPasswordEmailInProgress = false
        },
        onGettingAdminProfile(state, action) {
            state.gettingAdminProfile = true
        },
        onGettingAdminProfileSuccess(state, action) {
            state.gettingAdminProfile = false
            state.adminProfile = action.payload
        },
        onGettingAdminProfileFailed(state, action) {
            state.gettingAdminProfile = false
        },
        onSettingAdminProfile(state, action) {
            state.settingAdminProfile = true
        },
        onSettingAdminProfileSuccess(state, action) {
            state.settingAdminProfile = false
        },
        onSettingAdminProfileFailed(state, action) {
            state.settingAdminProfile = false
        },
        onGettingAdminPassPolicyeSuccess(state, action) {
            state.passwordPolicy = action.payload
        },
    }
})


export const sendForgetPasswordEmail = (email) => async dispatch => {
    dispatch(onSendingForgetPasswordEmail())
    try {
        let res = await forgetPassword(email)
        if (res.ok) {
            let response = res.obj
            console.log(response)
            if (response.error_code === 0) {
                dispatch(onSendingForgetPasswordEmailSuccess())
            } else {
                console.log("Error with error code")
                dispatch(onSendingForgetPasswordEmailFailed({
                    message: "Error with error code"
                }));
            }
            return response.error_code
        } else {
            console.log("Error with api call")
            dispatch(onSendingForgetPasswordEmailFailed({
                message: "Error with error code"
            }));
        }
        return 9
    } catch (err) {
        console.log(err);
        dispatch(onSendingForgetPasswordEmailFailed({
            message: err.toString()
        }));
        return 9
    }
}

export const resetAdminPassword = (password, token) => async dispatch => {
    dispatch(onResetPassword())
    try {
        let res = await resetPassword(password, token)
        if (res.ok) {
            let response = res.obj
            console.log(response)
            if (response.error_code === 0) {
                dispatch(onResetPasswordSuccess())
            } else {
                console.log("Error with error code")
                dispatch(onResetPasswordFailed({
                    message: "Error with error code"
                }));
            }
            return response.error_code
        } else {
            console.log("Error with api call")
            dispatch(onResetPasswordFailed({
                message: "Error with error code"
            }));
        }
        return 9
    } catch (err) {
        console.log(err);
        dispatch(onResetPasswordFailed({
            message: err.toString()
        }));
        return 9;
    }
}

export const getRecruitersInfo = (token) => async dispatch => {
    try {
        dispatch(onGettingRecruitersInfo())
        const res = await getRecruitersInfoApi(token)
        console.debug(res)
        if (res.ok && res.obj) {
            if (res.obj.error_code === 0) {
                console.debug(res.obj.result)
                dispatch(onGettingRecruitersInfoSuccess(res.obj.result))
            } else {
                console.log("Error on response");
                dispatch(onGettingRecruitersInfoFailed());
            }
        } else {
            console.log("Error on Network");
            dispatch(onGettingRecruitersInfoFailed());
        }
    } catch (err) {
        dispatch(onGettingRecruitersInfoFailed({
            message: err.toString()
        }));
        return {
            message: "Login Error"
        };
    }
}

export const getRecruiterInfo = (token, email) => async dispatch => {
    try {
        dispatch(onGettingRecruiterInfo())
        const res = await getRecruiterInfoApi(token, email)
        if (res.ok && res.obj) {
            if (res.obj.error_code === 0) {
                console.log(res.obj.result)
                dispatch(onGettingRecruiterInfoSuccess(res.obj.result))
            } else {
                console.log("Error on response");
                dispatch(onGettingRecruiterInfoFailed());
            }
        } else {
            console.log("Error on Network");
            dispatch(onGettingRecruiterInfoFailed());
        }
    } catch (err) {
        dispatch(onGettingRecruiterInfoFailed({
            message: err.toString()
        }));
        return {
            message: "Login Error"
        };
    }
}

export const getUsage = (token, comapny_groups) => async dispatch => {
    try {
        dispatch(onGettingUsage())
        const res = await getUsageApi(token, comapny_groups)
        if (res.ok && res.obj) {
            if (res.obj.error_code === 0) {
                console.debug(res.obj.result)
                dispatch(onGettingUsageSuccess(res.obj))
            } else {
                console.log("Error on response");
                dispatch(onGettingUsageFailed());
            }
        } else {
            console.log("Error on Network");
            dispatch(onGettingUsageFailed());
        }
    } catch (err) {
        dispatch(onGettingUsageFailed({
            message: err.toString()
        }));
        return {
            message: "Login Error"
        };
    }
}

export const getList = (token) => async dispatch => {
    try {
        dispatch(onGettingPurchasesList())
        const res = await getPurchasesList(token)
        // console.log(res)
        if (res.ok && res.obj) {
            if (res.obj.error_code === 0) {
                // console.log(res.obj.result)
                dispatch(onGettingPurchasesListSuccess(res.obj.result))
            } else {
                console.log("Error on response");
                dispatch(onGettingPurchasesListFailed());
            }
        } else {
            console.log("Error on Network");
            dispatch(onGettingPurchasesListFailed());
        }
    } catch (err) {
        dispatch(onGettingPurchasesListFailed({
            message: err.toString()
        }));
        return {
            message: "Login Error"
        };
    }
}

export const addNewAllocation = (token, allocations) => async dispatch => {
    try {
        console.log(allocations)
        dispatch(onAddingAllocation())
        const res = await addAllocation(token, allocations)
        if (res.ok && res.obj) {
            dispatch(onAddingAllocationSuccess());
            if (res.obj.error_code === 0) {
                return res.obj
            }
        } else {
            console.log("Error on Network");
            dispatch(onAddingAllocationFailed());
        }
    } catch (err) {
        dispatch(onAddingAllocationFailed({
            message: err.toString()
        }));
    }
}

export const createRecruiter = (token, recruiter_info) => async dispatch => {
    try {
        dispatch(onCreatingRecruiterAcc())
        const res = await createRecruiterApi(token, recruiter_info)

        // const res = await createRecruiterApi(
        //     token = token,
        //     email = recruiter_info.email,
        //     first_name = recruiter_info.firstName,
        //     last_name = recruiter_info.lastName,
        //     password = recruiter_info.password,
        //     videoInterview = recruiter_info.videoInterview ? 1 : 0,
        //     videoInterviewModel = (recruiter_info.videoInterview && recruiter_info.videoInterviewModel === "1" ? 1 : 0),
        //     ai_required = (recruiter_info.videoInterview && recruiter_info.aiRequired) ? 1 : 0,
        //     evaluation_required = 1,
        //     video_annotation_required = 1,
        //     company_group = recruiter_info.companyGroup,
        //     transcribe = recruiter_info.transcribe ? 1 : 0,
        //     logo = recruiter_info.videoInterviewModel ? recruiter_info.logo ? 1 : 0,
        //     logo_pic = recruiter_info.logo_pic,
        //     download_video = recruiter_info.download_video ? 1 : 0,
        //     add_file = recruiter_info.addFile ? 1 : 0,
        //     share_pdf_report = recruiter_info.shareReport ? 1 : 0,
        //     report_type = recruiter_info.shareReport && parseInt(recruiter_info.reportType))

        if (res.ok && res.obj) {
            if (res.obj.error_code === 0) {
                // console.debug(res.obj)
                dispatch(onCreatingRecruiterAccSuccess())
            } else {
                console.log("Error on response");
                dispatch(onCreatingRecruiterAccFailed());
            }
            return res.obj.error_code
        } else {
            console.log("Error on Network");
            dispatch(onCreatingRecruiterAccFailed());
        }
    } catch (err) {
        dispatch(onCreatingRecruiterAccFailed({
            message: err.toString()
        }));
        console.debug("admin.js: ", err)
        return {
            message: "Login Error"
        };
    }
}

export const fozenRecruiter = (token, emails) => async dispatch => {
    try {
        dispatch(onFozeningRecruiterAcc())
        const res = await fozenRecruiterAccApi(token, emails)
        if (res.ok && res.obj) {
            if (res.obj.error_code === 0) {
                console.debug(res.obj)
                dispatch(onFozeningRecruiterAccSuccess())
            } else {
                console.log("Error on response");
                dispatch(onFozeningRecruiterAccFailed());
            }
            return res.obj.error_code
        } else {
            console.log("Error on Network");
            dispatch(onFozeningRecruiterAccFailed());
        }
    } catch (err) {
        dispatch(onFozeningRecruiterAccFailed({
            message: err.toString()
        }));
        return {
            message: "Error"
        };
    }
}
export const reactivateRecruiter = (token, emails) => async dispatch => {
    try {
        dispatch(onReactivatingRecruiterAcc())
        const res = await reactivateRecruiterAccApi(token, emails)
        if (res.ok && res.obj) {
            if (res.obj.error_code === 0) {
                console.debug(res.obj)
                dispatch(onReactivatingRecruiterAccSuccess())
            } else {
                console.log("Error on response");
                dispatch(onReactivatingRecruiterAccFailed());
            }
            return res.obj.error_code
        } else {
            console.log("Error on Network");
            dispatch(onReactivatingRecruiterAccFailed());
        }
    } catch (err) {
        dispatch(onReactivatingRecruiterAccFailed({
            message: err.toString()
        }));
        return {
            message: "Error"
        };
    }
}

export const deleteRecruiter = (token, emails) => async dispatch => {
    try {
        dispatch(onDeletingRecruiterAcc())
        const res = await deleteRecruiterAccApi(token, emails)
        if (res.ok && res.obj) {
            if (res.obj.error_code === 0) {
                console.debug(res.obj)
                dispatch(onDeletingRecruiterAccSuccess())
            } else {
                console.log("Error on response");
                dispatch(onDeletingRecruiterAccFailed());
            }
            return res.obj.error_code
        } else {
            console.log("Error on Network");
            dispatch(onDeletingRecruiterAccFailed());
        }
    } catch (err) {
        dispatch(onDeletingRecruiterAccFailed({
            message: err.toString()
        }));
        return {
            message: "Error"
        };
    }
}

//export const updateRecruiter = (token, recruiter) => async dispatch => {
//    let ret = -1
//    try {
//        dispatch(onUpdatingRecruiter())
//        const res = await updateRecruiterApi(token, recruiter.email, recruiter.manual_review_required)
//        console.debug( res )
//        if (res.ok && res.obj) {
//            if (res.obj.error_code === 0) {
//                console.debug(res.obj)
//                dispatch(onUpdatingRecruiterSuccess())
//            } else {
//                console.log( `Error with error code (${res.obj.error_code})` )
//                dispatch(onUpdatingRecruiterFailed( {
//                  message: `Error with error code (${res.obj.error_code})`,
//                } ) )
//            }
//            ret = res.obj.error_code
//        } else {
//            console.log( 'Error with api call (ok)' )
//            dispatch(onUpdatingRecruiterFailed( {
//              message: 'Error with api call (ok)',
//            } ) )
//        }
//    } catch (err) {
//        console.log( err )
//        dispatch(onUpdatingRecruiterFailed({
//            message: err.toString()
//        }));
//    } finally {
//      return ret
//    }
//}

export const getAdminProfile = (token) => async dispatch => {
    try {
        dispatch(onGettingAdminProfile())
        const res = await getAdminProfileApi(token)
        if (res.ok && res.obj) {
            if (res.obj.error_code === 0) {
                console.debug(res.obj)

                const profile = {
                    email: res.obj.result && res.obj.result.email ? res.obj.result.email : '',
                    companyName: res.obj.result && res.obj.result.company_name ? res.obj.result.company_name : '',
                    //companyLogo: res.obj.result && res.obj.result.company_logo ? `/api/admin/get_admin_logo/${res.obj.result.company_logo}` : '',
                    companyLogo: res.obj.result && res.obj.result.company_logo ? await gets3object(res.obj.result.company_logo, token, BUCKETS.MEDIA_BUCKET) : '',
                    CSEmail: res.obj.result && res.obj.result.cs_email ? res.obj.result.cs_email : '',

                    opening: res.obj.result.opening,
                    add_quota: res.obj.result.add_quota,
                    suspension: res.obj.result.suspension,
                    reactivation: res.obj.result.reactivation,
                    out_of_quota: res.obj.result.out_of_quota,
                    low_quota: res.obj.result.low_quota,
                    return_quota: res.obj.result.return_quota,

                    opening_email: res.obj.result.opening_email,
                    add_quota_email: res.obj.result.add_quota_email,
                    suspension_email: res.obj.result.suspension_email,
                    reactivation_email: res.obj.result.reactivation_email,
                    out_of_quota_email: res.obj.result.out_of_quota_email,
                    low_quota_email: res.obj.result.low_quota_email,
                    return_quota_email: res.obj.result.low_quota_email,

                    opening_attachment: res.obj.result.opening_attachment,
                    add_quota_attachment: res.obj.result.add_quota_attachment,
                    suspension_attachment: res.obj.result.suspension_attachment,
                    reactivation_attachment: res.obj.result.reactivation_attachment,
                    out_of_quota_attachment: res.obj.result.out_of_quota_attachment,
                    low_quota_attachment: res.obj.result.low_quota_attachment,
                    return_quota_attachment: res.obj.result.return_quota_attachment,
                }
                dispatch(onGettingAdminProfileSuccess(profile))
            } else {
                console.log("Error on response");
                dispatch(onGettingAdminProfileFailed());
            }
            return res.obj.error_code
        } else {
            console.log("Error on Network");
            dispatch(onGettingAdminProfileFailed());
        }
    } catch (err) {
        dispatch(onGettingAdminProfileFailed({
            message: err.toString()
        }));
        return {
            message: "Error"
        };
    }
}

export const setAdminProfile = (token, values) => async dispatch => {
    console.log('test')
    try {
        dispatch(onSettingAdminProfile())
        console.log('test')
        const res = await setAdminProfileApi(token, values)
        console.log(res)
        if (res.ok && res.obj) {
            if (res.obj.error_code === 0) {
                console.debug(res.obj)
                dispatch(onSettingAdminProfileSuccess())
            } else {
                console.log("Error on response");
                dispatch(onSettingAdminProfileFailed());
            }
            return res.obj.error_code
        } else {
            console.log("Error on Network");
            dispatch(onSettingAdminProfileFailed());
        }
    } catch (err) {
        dispatch(onSettingAdminProfileFailed({
            message: err.toString()
        }));
        return {
            message: "Error"
        };
    }
}

export const adminSendEmail = (token, reciever, subject, content, attachment) => async dispatch => {
    try {
        const res = await adminSendEmailApi(token, reciever, subject, content, attachment)
        if (res.status == 202) {
            return res
        } else {
            console.log("Error on Network", res);
        }
    } catch (err) {
        return {
            message: "Error",
            result: err
        };
    }
}

export const set_admin_passPolicy = (args) => async dispatch => {
    try {
        const res = await edit_admin_passPolicy(args)
        return true
            // if (res.status == 202) {
        //     return res
        // } else {
        //     console.log("Error on Network", res);
        // }
    } catch (err) {
        return {
            message: "Error",
            result: err
        };
    }
}

export const get_admin_passPolicy = (token) => async dispatch => {
    try {
        const res = await get_admin_passPolicy_api(token)
        console.log(res,"res")
        dispatch(onGettingAdminPassPolicyeSuccess(res.obj.msg))
        return true
            // if (res.status == 202) {
        //     return res
        // } else {
        //     console.log("Error on Network", res);
        // }
    } catch (err) {
        return {
            message: "Error",
            result: err
        };
    }
}

export const {
    onGettingRecruitersInfo,
    onGettingRecruitersInfoSuccess,
    onGettingRecruitersInfoFailed,
    onGettingRecruiterInfo,
    onGettingRecruiterInfoSuccess,
    onGettingRecruiterInfoFailed,
    onGettingUsage,
    onGettingUsageSuccess,
    onGettingUsageFailed,
    onCreatingRecruiterAcc,
    onCreatingRecruiterAccSuccess,
    onCreatingRecruiterAccFailed,
    onFozeningRecruiterAcc,
    onFozeningRecruiterAccSuccess,
    onFozeningRecruiterAccFailed,
    onReactivatingRecruiterAcc,
    onReactivatingRecruiterAccSuccess,
    onReactivatingRecruiterAccFailed,
    onDeletingRecruiterAcc,
    onDeletingRecruiterAccSuccess,
    onDeletingRecruiterAccFailed,
    onGettingPurchasesList,
    onGettingPurchasesListSuccess,
    onGettingPurchasesListFailed,
    onAddingAllocation,
    onAddingAllocationSuccess,
    onAddingAllocationFailed,
    //    onUpdatingRecruiter,
    //    onUpdatingRecruiterSuccess,
    //    onUpdatingRecruiterFailed,
    search,
    changeCompanyGroupFilter,
    setCompanyFilter,
    setWeekFilter,
    onResetPassword,
    onResetPasswordSuccess,
    onResetPasswordFailed,
    onSendingForgetPasswordEmail,
    onSendingForgetPasswordEmailSuccess,
    onSendingForgetPasswordEmailFailed,
    onGettingAdminProfile,
    onGettingAdminProfileSuccess,
    onGettingAdminProfileFailed,
    onSettingAdminProfile,
    onSettingAdminProfileSuccess,
    onSettingAdminProfileFailed,
    onGettingAdminPassPolicyeSuccess,
} = adminSlice.actions;

export default adminSlice.reducer;