export const sections = [
  'Impression',
  'Task_Performance',
  'Contextual_Performance',
  'Adaptive_Performance',
  'Potential_Work_Performance'
]

export const categories = {
  'Impression': [
    'Enthusiasm',
    'Composure',
    'Self_Confidence',
  ],
  'Potential_Work_Performance': [
    'Task_Performance',
    'Interpersonal_Relationship_Communication',
    'Adaptability',
  ],
  'Task_Performance': [
    'Leadership',
    'Developing_People',
    'Goal_And_Achievement_Striving',
    'Responsibility',
    'Managing_Execution',
    'Analytical_And_Logical_Thinking',
    'Complex_Problem_Solving',
    'Vision_And_Entrepreneurial_Mindset',
    'Technology_Expertise_And_Business_Sense',
  ],
  'Contextual_Performance': [
    'Communication',
    'Caring_And_Empathy',
    'English_Oral_Communication',
    'Cantonese_Oral_Communication',
    'Mandarin_Oral_Communication',
    'Interpersonal_Relationship',
    'Team_Collaboration_And_Inclusion',
    'Building_Relationships_And_Network',
    'Effective_Communication_And_Teambuilding',
  ],
  'Adaptive_Performance': [
    'Flexibility_And_Adaptability',
    'Learning_Agility',
    'Creativity_And_Innovation',
    'Emotional_Intelligence',
    'Grit_And_Passion',
  ],
}

export const big5 = [
  'Openness',
  'Conscientiousness',
  'Extraversion',
  'Agreeableness',
  'Neuroticism',
]
