import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import isNil from 'lodash/isNil'
export const getBrowserName = () => {
  let browser = 'Others'
  const userAgent = defaultTo(get(navigator, 'userAgent', ''), '')
  // EDGE
  if (userAgent.indexOf("Edge") !== -1) {
    browser = 'Microsoft Edge'
  }
  // EDGE (Chromium-based)
  else if (userAgent.indexOf("Edg") !== -1) {
    browser = 'Microsoft Edge (Chromium-based)'
  }
  // OPERA
  else if (userAgent.indexOf("OPR") !== -1) {
    browser = 'Opera'
  }
  // CHROME
  else if ((userAgent.indexOf("Chrome") !== -1) || (userAgent.indexOf("CriOS") !== -1)) {
    browser = 'Google Chrome'
  }
  // FIREFOX
  else if (userAgent.indexOf("Firefox") !== -1) {
    browser = 'Mozilla Firefox'
  }
  // INTERNET EXPLORER
  else if ((userAgent.indexOf("MSIE") !== -1) || (userAgent.indexOf("rv") !== -1)) {
    browser = 'Internet Explorer'
  }
  // SAFARI
  else if (userAgent.indexOf("Safari") !== -1) {
    browser = 'Safari'
  }
  //// OPERA
  //else if (userAgent.indexOf("Opera") !== -1) {
  //  browser = 'Opera'
  //}
  return browser
}

export const isRightBrowser = (phoneName, browserName) => {
  // v1
  //return browserName === 'Google Chrome' || browserName === 'Mozilla Firefox' || browserName === 'Opera'
  // v2
  //return browserName === 'Google Chrome' || browserName === 'Mozilla Firefox'
  //if (isNil(phoneName)) {
  return browserName === 'Google Chrome' || browserName === 'Mozilla Firefox' || browserName === 'Microsoft Edge (Chromium-based)' || browserName === 'Safari'
  //} else {
  //  return phoneName !== 'iOS' &&
  //    (browserName === 'Google Chrome' || browserName === 'Mozilla Firefox' || browserName === 'Microsoft Edge (Chromium-based)')
  //}
}

export const getPhoneName = () => {
  let phone = 'Others' // may be desktop
  const userAgent = defaultTo(get(navigator, 'userAgent', ''), '')
  // if( userAgent.indexOf( 'iPhone' ) !== -1 ) {
  //   phone = 'iPhone'
  // } else if( userAgent.indexOf( 'iPad' ) !== -1 ) {
  //   phone = 'iPad'
  // } else if( userAgent.indexOf( 'Android' ) !== -1 ) {
  //   phone = 'Android'
  // } else if(navigator.userAgent.match('CriOS')) {
  //   // could be iphone or ipad
  //   phone = 'iPhone'
  // }
  if (userAgent.indexOf('Android') !== -1) {
    phone = 'Android'
  } else if (deviceIsIOS()) {
    phone = "iOS"
  }
  return phone
}

export const deviceIsIOS = () => {
  let isIOS = false
  const userAgent = defaultTo(get(navigator, 'userAgent', ''), '')
  if (userAgent.indexOf('iPhone') !== -1) {
    isIOS = true
  } else if (userAgent.indexOf("iPad") !== -1) {
    isIOS = true
  } else if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) {
    isIOS = true
  } else if (navigator.userAgent.match('CriOS')) {
    isIOS = true
  } else if (navigator.platform === "iPhone") {
    isIOS = true
  } else if (navigator.platform === "iPad") {
    isIOS = true
  }
  return isIOS
}

export const isCriOS = () => {
  let isCriOS = false
  let userAgent = navigator.userAgent
  console.log("using: ", userAgent)
  if (userAgent.match('CriOS')){
    isCriOS = true
  }
  return isCriOS
}

export const getIOSVersion = () => {
  /**
   * Prototype: navigator.userAgent.slice(navigator.userAgent.indexOf('OS')+3,navigator.userAgent.indexOf('OS')+7).replace( '_', '.' );
   */
  let platformString = navigator.userAgent;
  let versionNum = parseFloat('-1')
  if(['iPhone','iPad'].includes(navigator.platform)){
    versionNum = parseFloat(platformString.slice(platformString.indexOf('OS')+3,platformString.indexOf('OS')+7).replace( '_', '.' ));
  }
  console.debug(`browserUtils.js: versionNum ${versionNum} and type: ${typeof(versionNum)}`)
  return versionNum
}

