import { createSlice } from 'redux-starter-kit'
import { getStateFromCookies } from 'redux-cookies-middleware';
import _ from 'lodash';

// state to persist in cookies
const cookiePathMapping = {
  'language': { name: 'lang' },
  'loginRole': { name: 'loginRole' },
  'brightness': { name: 'brightness' },
  'gain': { name: 'gain' },
  'volume': { name: 'volume' },
};

const getBrowserLanguage = () => {
  const browserLang = navigator.language
  console.log(browserLang, '==========')
  if (/^en/.test(browserLang)) {
    return "en"
  }
  if (/^zh(-HK|-TW)/.test(browserLang)) {
    return "tc"
  }
  if (/^zh/.test(browserLang)) {
    return "sc"
  }
  if (/^th/.test(browserLang)) {
    return "th"
  }
  if (/^ja/.test(browserLang)) {
    return "ja"
  }

  if (/^id/.test(browserLang)) {
    return "id"
  }

  if (/^ms/.test(browserLang)) {
    return "ms"
  }

  if (/^vi/.test(browserLang)) {
    return "vi"
  }
  if (/^km/.test(browserLang)) {
    return "km"
  }  

  return "en"
}

/**
 * Checks and returns compatible video format (codec + encoding pair)
 * 21 November, 2022: Media Recorder supports -
 * video/webm;codecs=vp8,opus: Chrome, Firefox, Brave, Edge
 * video/mp4;codecs=avc1.4d002a: Safari
 * @returns string
 */

const getVideoFormat = () => {
  try {
    if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8,opus")) {
      return "video/webm;codecs=vp8,opus"
    } else if (MediaRecorder.isTypeSupported("video/mp4")) {
      return "video/mp4"
    } else {
      console.debug(`Media Recorder API does not seem to support the required video formats in this browser. Resorting to default format`)
      return "video/webm"
    }
  } catch (error) {
    console.error(error)
    return "video/mp4"
  }

}

let initialState = {
  language: getBrowserLanguage(),
  loginRole: 'jobseeker',
  brightness: 50,
  volume: 0.5,
  gain: 1,
  colorScheme: null,
  videoFormat: getVideoFormat()
};
initialState = { ...initialState, ..._.omitBy(getStateFromCookies({}, cookiePathMapping), _.isNil) };

const preferenceSlice = createSlice({
  slice: 'preference',
  initialState: initialState,
  reducers: {
    setLanguagePref(state, action) {
      const language = action.payload;
      state.language = language;
    },
    setLoginRole(state, action) {
      const loginRole = action.payload;
      state.loginRole = loginRole;
    },
    setBrightness(state, action) {
      const brightness = action.payload;
      state.brightness = brightness;
    },
    setVolume(state, action) {
      const volume = action.payload;
      state.volume = volume;
    },
    setGain(state, action) {
      const gain = action.payload;
      state.gain = gain;
    },
    setColorScheme(state, action) {
      const colorScheme = action.payload;
      state.colorScheme = colorScheme;
    },
  }
});

export const { setLanguagePref, setLoginRole, setBrightness, setVolume, setGain, setColorScheme } = preferenceSlice.actions;

export default preferenceSlice.reducer;
