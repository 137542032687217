import { ErrorOutlined } from '@material-ui/icons';
import uuidv1 from 'uuid/v1'
import Swagger from 'swagger-client'
import config from '../config'
import clientRequest from '../constants/clientRequest';
// const clientRequest = Swagger(swaggerSpec).then(client => {
//     //   if (process.env.NODE_ENV === 'test') {
//     //     client.spec.host = 'localhost:9003';
//     //   }
//     return client;
// });
const tag = 'api/s3';

export const gets3object = (object_name, token, bucket_name) => {
    return new Promise((resolve, reject) => {
        const param = {
            "bucket_name": bucket_name,
            "object_name": object_name,
            "token": token
        }
        const request = {
            url: `${config.baseUrl}/api/s3/get_s3_object_url`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(param),
        };
        clientRequest.then(client=>{
        client.http(request).then(
            res => {
                resolve(res.body)
            },
            err => {
                reject(err)
            })
    })
    })
}

export const gets3objectWithJobId = (object_name, token, bucket_name) => {
    return new Promise((resolve, reject) => {
        const param = {
            "bucket_name": bucket_name,
            "object_name": object_name,
            "token": token
        }
        const request = {
            url: `${config.baseUrl}/api/s3/get_s3_object_url_unsafe`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(param),
        };
        clientRequest.then(client=>{
        client.http(request).then(
            res => {
                resolve(res.body)
            },
            err => {
                reject(err)
            })
    })
    })
}


export const getMultipleObject = (interview_id, token) => {
    return new Promise((resolve, reject) => {
        const param = {
            "interview_id": interview_id,
        }
        const request = {
            url: "https://iudn371087.execute-api.ap-southeast-1.amazonaws.com/prod",
            //url: `localhost:8000/api/s3/download_multiple_object`,
            method: 'GET',
            query: param,
            headers: {
                'x-api-key': 'A3hrrQM8Bo7kHSlBwGfGH2MOL3RxWp6S7vHInVvq',

            },

        };

        clientRequest.then(client=>{
        client.http(request).then(
            res => {
                resolve(res.body)
            },
            err => {
                reject(err)
            })
        })
    })
}

// unused functio
export const posts3Object = (object_name, token, progressBack) => {
    return clientRequest.then(client => {
        const param = {
            "object_name": object_name,
            "token": token
        }
        const request = {
            url: `${config.baseUrl}/api/s3/create_s3_upload_url`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(param),
        };
        
        client.http(request).then(res => {
            progressBack(res)
        })
    })
}

export const getExists3File = (object_name, token) => {
    return new Promise((resolve, reject) => {
        gets3object(object_name, token).then(res => {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open("GET", res.body, false); // false for synchronous request
            xmlHttp.send();
            if (xmlHttp.status == 200) {
                resolve(res.body)
            } else {
                reject(xmlHttp)
            }
        },
            err => {
                reject(err)
            })
    })
}

export const create_upload = (object_name, token, bucket_name) => {
    return new Promise((resolve, reject) => {
        const param = {
            "bucket_name": bucket_name,
            "object_name": object_name,
            "token": token
        }
        const request = {
            url: `${config.baseUrl}/api/s3/generate_upload_id`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(param),
        };
        clientRequest.then(client=>{
            client.http(request).then(
                res => {
                    resolve(res)
                },
                err => {
                    reject(err)
                })
        })
    }
    )
}

export const multi_upload = (object_name, upload_id, part_no, token, bucket_name) => {
    return new Promise((resolve, reject) => {
        const param = {
            "bucket_name": bucket_name,
            "object_name": object_name,
            "upload_id": upload_id,
            "part_no": part_no,
            "token": token
        }
        const request = {
            url: `${config.baseUrl}/api/s3/upload_multipart_s3_object`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(param),
        };
        clientRequest.then(client=>{
            client.http(request).then(
                res => {
                    resolve(res)
                },
                err => {
                    reject(err)
                })
        })

    })
}

export const complete_upload = (object_name, upload_id, parts, token, bucket_name, candidate) => {
    return new Promise((resolve, reject) => {
        const param = {
            "candidate": candidate === "yes" ? "yes" : "no",
            "bucket_name": bucket_name,
            "object_name": object_name,
            "parts": parts,
            "token": token,
            "upload_id": upload_id
        }
        const request = {
            url: `${config.baseUrl}/api/s3/complete_multipart_s3_upload`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(param),
        };
        // console.log(request, "_____+++++======+_________")
        clientRequest.then(client=>{
            client.http(request).then(
                res => {
                    resolve(res)
                },
                err => {
                    reject(err)
                })
        })

    })
}

export const s3_upload_object_url = (token, bucket_name, object_name, blob) => {
    return new Promise((resolve, reject) => {
        var data = new FormData()
        data.append("token",token)
        data.append("bucket_name",bucket_name)
        data.append("object_name",object_name)
        data.append("object_file",blob)
        const request = {
            url: `${config.baseUrl}/api/s3/put_s3_object_url`,
            method: 'POST',
            body: data
        };
        clientRequest.then(client=>{
            client.http(request).then(
                res => {
                    resolve(res)
                },
                err => {
                    reject(err)
                })
        })
        
    })
}

export const create_s3_upload_url_simple = (...args) => {
    const formData = new FormData()
    const params = ["bucket_name", "file", "object_name", "token"]
    if (args.length == 5 && args[4] != null) params.push("content_type")
    for ( let i in params) formData.append(params[i], args[i])
    
    
    const request = {
        url: `${config.baseUrl}/api/s3/create_s3_upload_url_simple`,
        method: 'POST',
        body: formData,
    };
    return clientRequest.then(client=>client.http(request))
}

export const fetch_get_s3_object_url = (args) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_get_s3_object_urlapi({
      payload: args
    })
  })
}

export const checkS3ObjectExists = (token, bucket_name, object_name) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_check_object_exists(
        {
            payload: {
            token,
            bucket_name,
            object_name
            }
        }
    )
  })
}

export const processSimpleVideoUploadApi = (token, bucket_name, object_name) => {
    return clientRequest.then((client) => {
        return client.apis[tag].post_process_simple_video_upload(
            {
                payload: {
                    token,
                    bucket_name,
                    object_name
                }
            }
        )
    })
}

export const createS3UploadPresignedURL = (payload) => {
    return clientRequest.then((client) => {
        return client.apis[tag].post_create_simple_presigned_url({
        payload: payload
        })
    })
}

const s3access = {
    gets3object,
    posts3Object,
    getExists3File,
    create_upload,
    multi_upload,
    complete_upload,
    create_s3_upload_url_simple,
    s3_upload_object_url,
    checkS3ObjectExists,
    processSimpleVideoUploadApi,
    createS3UploadPresignedURL,
};

export default s3access;
