import clientRequest from "../constants/clientRequest"

const tag = 'api/client';

export const checkUrlHash = (url_hash) => {
    return clientRequest.then( client => {
        console.log(client)
        return client.apis[tag].post_client_check_url_hash({
            payload: {
                url_hash: url_hash,
            }
        })
    })
}

export const checkLoginSso = (url_hash) => {
    return clientRequest.then( client => {
        return client.apis[tag].post_login_sso({
            payload: {
                url_hash: url_hash,
            }
        })
    })
}

export const callAIARefreshToken = (token, sso_token) => {
    return clientRequest.then( client => {
        return client.apis[tag].post_call_refresh_token({
            payload: {
                token: token,
                sso_token: sso_token,
            }
        })
    })
}

export const callAIALogoutToken = (token, sso_token) => {
    return clientRequest.then( client => {
        return client.apis[tag].post_call_logout_token({
            payload: {
                token: token,
                sso_token: sso_token,
            }
        })
    })
}

const client = {
    checkUrlHash,
    checkLoginSso,
}

// export default clientimport clientRequest from "../constants/clientRequest"

// const tag = 'api/client'

export const postSyncSelfIntroVideoStatus = (token) => {
    return clientRequest.then(client => {
      return client.apis[tag].post_sync_self_intro_video_status({
        payload: {
          token
        }
      })
    })
  }

export const postSyncSelfIntroVideo = (token, ssoToken, objectName) => {
    return clientRequest.then(client => {
      return client.apis[tag].post_transfer_self_intro_video({
        payload: {
            token,
            sso_token: ssoToken,
            object_name: objectName,
        }
      })
    })
  }