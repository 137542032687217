import {createSlice} from 'redux-starter-kit'
import {loadCV, saveCV} from "../apis/resume";

let initialState = {
    candidateCV: null,
    loadingCV: false,
    savingCV: false,
    infoUploadedFetching: false,
};

const candidateSlice = createSlice({
    slice: 'candidate',
    initialState: initialState,
    reducers: {
        onLoadingCV(state, action) {
            state.loadingCV = true
        },
        onLoadingCVSuccess(state, action) {
            state.candidateCV = action.payload;
            state.loadingCV = false
        },
        onLoadingCVFailed(state, action) {
            state.loadingCV = false
        },
        onSavingCV(state, action) {
            state.savingCV = true;
        },
        onSavingCVSuccess(state, action) {
            state.candidateCV = action.payload;
            state.savingCV = false
        },
        onSavingCVFailed(state, action) {
            state.savingCV = false
        },
        onLoadingInfoState(state, action) {
            state.infoUploadedFetching = true
        },
        onLoadingInfoStateSuccess(state, action) {
            state.infoUploaded = action.payload
            state.infoUploadedFetching = false
        },
        onLoadingInfoStateFailed(state, action) {
            state.infoUploaded = false
            state.infoUploadedFetching = false
        }
    }
});

export const {
    onLoadingCV,
    onLoadingCVSuccess,
    onLoadingCVFailed,
    onSavingCV,
    onSavingCVSuccess,
    onSavingCVFailed,
    onLoadingInfoState, 
    onLoadingInfoStateSuccess,
    onLoadingInfoStateFailed
} = candidateSlice.actions;

export default candidateSlice.reducer;


function transformCVResult(result) {
    const transformed = {
        workExperiences: [],
        educations: [],
        certificatesAndAwards: [],
        skills: [],
        basicInfo: {
            FullName: result.BasicInfo? result.BasicInfo.FullName || '': '',
            Email: result.BasicInfo? result.BasicInfo.Email || '' : '',
            PhoneNumber: result.BasicInfo? result.BasicInfo.PhoneNumber || '': '',
            Address: result.BasicInfo? result.BasicInfo.Address || '': '',
        }
    };
    if (result.WorkExp) {
        result.WorkExp.forEach(exp => {
            transformed.workExperiences.push(exp);
        })
    }
    if (result.ResumeSkills) {
        result.ResumeSkills.forEach(skill => {
            transformed.skills.push(skill.Skill);
        })
    }
    if (result.Education) {
        result.Education.forEach(education => {
            transformed.educations.push({
                ...education,
                active: education.StillActive === 'Yes'
            });
        })
    }
    if (result.Certificates) {
        result.Certificates.forEach(cert => {
            transformed.certificatesAndAwards.push(cert);
        })
    }
    return transformed;
}

/**
 *
 * @param {String} job_id
 * @param {String} token
 */
export const getCandidateCV = (candidateEmail, jobId, token) => async dispatch => {
    dispatch(onLoadingCV());
    try {
        const res = await loadCV(candidateEmail, jobId, token);
        if (res.ok && res.obj && res.obj.error_code === 0) {
            const cv = transformCVResult(res.obj.result);
            cv.job_id = jobId;
            cv.jobseeker_email = candidateEmail;
            dispatch(onLoadingCVSuccess(cv));
            return true;
        } else {
            console.log("Error with api call")
            dispatch(onLoadingCVFailed());
        }
        return false;
    } catch (err) {
        console.log(err);
        dispatch(onLoadingCVFailed({
            message: err.toString()
        }));
        return false;
    }
};

function toDBFormat(cv) {
    const dbCV = {
        BasicInfo: cv.basicInfo,
        Certificates: [],
        Education: [],
        ResumeSkills: [],
        WorkExp: [],
        CertMaxId: cv.certificatesAndAwards.length,
        EduMaxId: cv.educations.length,
        ResumeSkillMaxId: cv.skills.length,
        WorkExpMaxId: cv.workExperiences.length,
    };
    cv.certificatesAndAwards.forEach((cert, index) => {
        dbCV.Certificates.push({
            ...cert,
            id: index + 1,
        })
    });
    cv.educations.forEach((education, index) => {
        dbCV.Education.push({
            ...education,
            id: index + 1,
        })
    });
    cv.skills.forEach((skill, index) => {
        dbCV.ResumeSkills.push({
            Skill: skill,
            id: index + 1,
        })
    });
    cv.workExperiences.forEach((work, index) => {
        dbCV.WorkExp.push({
            ...work,
            id: index + 1,
        })
    });
    return dbCV;
}

export const saveCandidateCV = (candidateEmail, jobId, token, cv) => async dispatch => {
    dispatch(onSavingCV());
    try {
        const saveCv = toDBFormat(cv);
        const res = await saveCV(candidateEmail, jobId, token, saveCv);
        if (res.ok && res.obj && res.obj.error_code === 0) {
            const updatedCv = transformCVResult(saveCv);
            updatedCv.job_id = jobId;
            updatedCv.jobseeker_email = candidateEmail;
            dispatch(onSavingCVSuccess(updatedCv));
            return true;
        } else {
            console.log("Error with api call");
            dispatch(onSavingCVFailed());
        }
        return false;
    } catch (err) {
        console.log(err);
        dispatch(onSavingCVFailed({
            message: err.toString()
        }));
        return false;
    }
};