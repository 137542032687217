import clientRequest from "../constants/clientRequest"

const tag = 'api/jobseeker';

export const login = (LoginID, password) => {
  return clientRequest
    .then(client => {
      // Tags interface
      console.log(LoginID,"value")
      return client.apis[tag].post_jobseeker_login({
        payload: {
          LoginID,
          password,
        }
      });
    })
};

export const tokenLogin = (email, token, urlHash) => {
  return clientRequest.then(client => {
    return client.apis[tag].post_jobseeker_login_via_token({
      payload: {
        email: email,
        token: token,
        url_hash: urlHash,
      }
    })
  })
}

export const logout = (token) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_jobseeker_logout({
      payload: {
        token,
      }
    });
  })
};

export const fetchStatus = (token) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_jobseeker_fetch_status({
      payload: {
        token,
      }
    });
  })
};

export const fetchSetting = (token) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_jobseeker_fetch_setting({
      payload: {
        token,
      }
    });
  })
};
export const fetchSettingInDangerousEnvironment = (token, jobId) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_jobseeker_fetch_setting_unsafe({
      payload: {
        token: token,
        job_id: jobId,
      }
    });
  })
};

export const postupdateNumberOfAttempts = (token, reset) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_update_interview_attempt({
      payload: {
        token: token,
        reset: reset
      }
    });
  })
};

export const fetchVideoStatus = (token) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_fetch_video_status({
      payload: {
        token
      }
    })
  })
};

export const getCompanyName = (token) => {
  return clientRequest.then(client => {
    return client.apis[tag].post_jobseeker_get_company_name({
      payload: {
        token
      }
    })
  })
};

export const getInterviewStarted = (token, question_id) => {
  return clientRequest.then(client => {
    return client.apis[tag].post_jobseeker_check_status({
      payload: {
        token,
        question_id
      }
    })
  })
};

export const postInterviewVideoStatus = (token, question_id, attempt_number, file_size) => {
  return clientRequest.then(client => {
    return client.apis[tag].post_jobseeker_interview_video_status({
      payload: {
        token,
        question_id,
        attempt_number,
        file_size
      }
    })
  })
}

export const getVideo = (jobId) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].get_get_video({
      job_id: jobId,
    });
  })
};

export const getLogo = (jobId) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].get_get_logo({
      job_id: jobId
    });
  });
};

export const getFinalMessage = (token) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_jobseeker_get_final_message({
      payload: {
        token,
      }
    });
  },
    err => {
      return 'error get final message'
    })
};

export const getInfos = (token) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_jobseeker_get_languge({
      payload: {
        token,
      }
    })
  })
};

export const assignQuestion = (token) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_jobseeker_assign_questionset({
      payload: {
        token,
      }
    });
  })
};

export const getQuestion = (token) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_get_questions({
      payload: {
        token,
      }
    });
  })
};

export const judging = (token) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_activate({
      payload: {
        token,
      }
    });
  })
};

export const checkUrlHash = url_hash => {
  return clientRequest.then(client => {
    return client.apis[tag].post_check_url_hash({
      payload: {
        url_hash,
      }
    })
  })
}

export const loginViaUrlApi = code => {
  return clientRequest.then(client => {
    return client.apis[tag].post_jobseeker_login_via_url({
      payload: {
        code,
      }
    })
  })
}

export const recordRedirectStatusApi = (jobseeker_code, error_code, token) => {
  return clientRequest.then(client => {
    return client.apis[tag].post_jobseeker_record_redirect_status({
      payload: {
        jobseeker_code,
        error_code,
        token
      }
    })
  })
}

export const markRetryApi = (token, retry) => clientRequest.then(client => {
  const payload = {
    token,
  }
  console.debug({ payload })
  return client.apis[tag].post_jobseeker_mark_retry({ payload })
})

export const getS3VideoStatusesApi = token => clientRequest.then(client => {
  const payload = {
    token,
  }
  console.debug({ payload })
  return client.apis[tag].post_jobseeker_list_s3_files({ payload })
})

export const updateTestStatusApi = (token, current_question_test) => {
  return clientRequest.then(client => {
    return client.apis[tag].post_jobseeker_set_test_status({
      payload: {
        token,
        current_question_test
      }
    })
  })
}

export const assignTestDeadlineApi = (token, current_question_test, testDeadline) => {
  return clientRequest.then(client => {
    return client.apis[tag].post_jobseeker_assign_test_deadline({
      payload: {
        token,
        current_question_test,
        testDeadline
      }
    })
  })
}

export const updateCurrentQuestionApi = (token, current_question_test, current_question) => clientRequest.then(client => {
  return client.apis[tag].post_jobseeker_update_currentquestion({
    payload: {
      token,
      current_question_test,
      current_question,
    }
  })
})

export const candidateUploadCV = (token, jobID, cvName, cvExtension, cvPersonalInfo, cvRawInfo, object_name, bucket_name) => clientRequest.then(client => {
  return client.apis[tag].post_upload_cv_from_candidate({
    payload: {
      token,
      job_id: jobID,
      cv_name: cvName,
      cv_extension: cvExtension,
      cv_person_info: cvPersonalInfo,
      cv_raw_person_info: cvRawInfo,
      cv_file_s3_object_name: object_name,
      cv_file_s3_bucket_name: bucket_name
    }
  })
})

export const getConsentCandidate = (token) => clientRequest.then(client => {
  console.log("client.apis[tag]: ", client.apis[tag])
  return client.apis[tag].post_fetch_consent_data_jobseeker({
    payload: {
      token,
    }
  })
})

export const setConsentStatusCandidate = (token, consentId, action) => clientRequest.then(client => {
  return client.apis[tag].post_create_consent_status({
    payload: {
      token,
      consent_id: consentId,
      action: action
    }
  })
})

export const checkCandidateConsentStatus = (token) => clientRequest.then(client => {
  return client.apis[tag].post_check_consent_status_jobseeker({
    payload: {
      token,
    }
  })
})

export const createConsentStatus = (token, consentID, actionType) => clientRequest.then(client => {
  return client.apis[tag].post_create_consent_status({
    payload: {
      token,
      consent_id: consentID,
      action: actionType
    }
  })
})

/**
 * Call this endpoint to send otp code to the passed channels (default being email) via the Twilio Verify API
 * @param {string} email 
 * @param {string} channel 
 * @returns 
 */
export const sendOTPCode = (username, email, jobName, channel) => {
  return clientRequest.then(client => client.apis[tag].post_send_otp_code({
    payload: {
      username: username,
      email: email,
      job_name: jobName,
      channel: channel,
    }
  }))
}

/**
 * Verify the code received and entered by the candidate 
 * @param {string} email 
 * @param {string} code 
 * @returns 
 */
export const verifyOTPCode = (email, code, jobId) => {
  return clientRequest.then(client => client.apis[tag].post_verify_otp_jobseeker({
    payload: {
      job_id: jobId,
      email: email,
      code: code,
    }
  }))
}

export const retrieveCaptchaSiteKey = (urlHash) => {
  return clientRequest.then(client => client.apis[tag].get_retrieve_captcha_sitekey({
    url_hash: urlHash,
  }))
}

export const verifyCaptchaToken = (email, captcha_token, jobId) => {
  return clientRequest.then(client => client.apis[tag].post_verify_captcha_jobseeker({
    payload: {
      job_id: jobId,
      email: email,
      captcha_token: captcha_token,
    }
  }))
}

/**
 * Import Candidate using Access Code
 * @param {*} token 
 * @param {*} name 
 * @returns 
 */
export const importCandidateByAccessCode = (token, name, job_id) => {
  return clientRequest.then(client => {
    return client.apis[tag].post_import_candidates_by_access_key({
      payload: {
        token: token,
        name: name,
        job_id: job_id,
      }
    })
  })
}

export const fetchLanguagePreference = (token) => {
  return clientRequest.then( client => {
    return client.apis[tag].post_get_candidate_language_preference({
      payload: {
        token
      }
    })
  })
}

export const storeCandidateLanguagePreference = (token, language) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_store_candidate_language_preference({
      payload: {
        token,
        language,
      }
    })
  })
}

export const fetch_candidate_is_interview_started = (interviewId) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_fetch_candidate_is_interview_started({
      payload: {
        interviewId
      }
    })
  })
}

export const process_image = (payload) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_process_image({
      payload: payload
    })
  })
}

export const process_audio = (payload) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_process_voice({
      payload: payload
    })
  })
}

export const check_voice_activation_detection = (token, interviewId, questionNum, attemptNum) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_check_voice_activation_detection({
      payload: {
        token: token,
        interviewId: interviewId,
        questionNum: questionNum,
        attemptNum: attemptNum,
      }
    })
  })
}

export const check_default_status = (payload) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_check_default_status({
      payload: payload
    })
  })
}

export const check_self_intro_default_status = (payload) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_check_self_intro_default_status({
      payload: payload
    })
  })
}

export const updateSelfIntroAttempt = (token, interviewId) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_update_self_intro_attempts({
      payload:{
        token: token,
        interviewId: interviewId,
      }
    })
  })
}
export const updateVideoInterviewRetryIndex = (token) => {
  return clientRequest.then(client => {
    return client.apis[tag].post_update_video_interview_retry_index({
      payload: {
        token: token
      }
    })
  })
}

const jobseeker = {
  login,
  logout,
  fetchStatus,
  fetchVideoStatus,
  getCompanyName,
  getVideo,
  assignQuestion,
  getQuestion,
  getLogo,
  getInfos,
  getFinalMessage,
  judging,
  fetchSetting,
  loginViaUrlApi,
  recordRedirectStatusApi,
  markRetryApi,
  getS3VideoStatusesApi,
  updateTestStatusApi,
  assignTestDeadlineApi,
  updateCurrentQuestionApi,
  getConsentCandidate,
  setConsentStatusCandidate,
  checkCandidateConsentStatus,
  createConsentStatus,
  postupdateNumberOfAttempts,
  updateSelfIntroAttempt,
  updateVideoInterviewRetryIndex
};

export default jobseeker;
