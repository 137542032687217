import {
    createSlice
} from 'redux-starter-kit'
import {
    checkTaskComplete,
    exportVideo,
    getStat,
    zipVideo,
    fetchExportedFiles,
} from '../apis/statistics'
import {
    action,
    reportAction,
    sapStatusChangeAPI,
    sapStatusResetAPI,
    fetchStatus,
    setRedo,
    changeCandidateStatus,
    updateInformation,
    updateCandidateInformation,
    updateAccountInformation,
    updateAccountPassPolicy,
    verify_sap_account_api,
    changePassword,
    getUsage,
    changeCandidateDeadline,
    getPurchases,
    getPurchaseUsages,
    getPurchaseBalance,
    register,
    getRemainingQuota,
    resetPassword,
    forgetPassword,
    inviteEvaluation,
    fetchJobInfoByGuest,
    redoQuestionsApi,
    updateAnnotationApi,
    fetchOngoingInterviewApi,
} from '../apis/recruiter'
import {
    getReport,
    getReportByGuest

} from '../apis/reports'
import {
    sections,
    categories
} from '../constants/competencies'
import get from 'lodash/get'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

import { create_s3_upload_url_simple } from '../apis/s3access'
import axios from 'axios'
import { BUCKETS, SESSION_CONSTANTS } from '../constants/constants'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

var log = require('loglevel')

let initialState = {
    firstname: "",
    lastname: "",
    activation: 0,
    statFilter: "overall_score",
    statusFilter: "Recruiter_Dashboard_Status_Filter_All",
    currentViewingStatistics: {},
    isOutOfQuota: false,
    logo_pic: null,
    parse_cv_function: 0,
    ai_required: 0,
    manual_review_required: 0,
    sessionDuration: SESSION_CONSTANTS.DEFAULT_SESSION_DURATION,
    currentViewingReport: {
        email: "",
        //score: {
        //    "communication": "-",
        //    "composure": "-",
        //    "creativity_innovation": "-",
        //    "emotional_intelligence": "-",
        //    "enthusiasm": "-",
        //    "eye_contact": "-",
        //    "goal_achievement": "-",
        //    "impression_score": "-",
        //    "impression_weight": "-",
        //    "interpersonal_relationship": "-",
        //    "leadership": "-",
        //    "learning_ability": "-",
        //    "overall_score": "-",
        //    "pace": "-",
        //    "potential_work_score": "-",
        //    "potential_work_weight": "-",
        //    "self_confidence": "-",
        //    "speed_of_speech": "-"
        //},
        score: reduce([].concat(...map(sections, section => {
            return map(categories[section], category => `${category.toLowerCase()}_weight`)
        })), (acc, x) => Object.assign(acc, {
            [x]: '-'
        }), {}),
        //weighting: [{
        //        "name": "enthusiasm_weight",
        //        "score": "-"
        //    },
        //    {
        //        "name": "self_confidence_weight",
        //        "score": "-"
        //    },
        //    {
        //        "name": "composure_weight",
        //        "score": "-"
        //    },
        //    {
        //        "name": "pace_weight",
        //        "score": "-"
        //    },
        //    {
        //        "name": "leadership_weight",
        //        "score": "-"
        //    },
        //    {
        //        "name": "learning_agility_weight",
        //        "score": "-"
        //    },
        //    {
        //        "name": "creativity_and_innovation_weight",
        //        "score": "-"
        //    },
        //    {
        //        "name": "emotional_intelligence_weight",
        //        "score": "-"
        //    },
        //    {
        //        "name": "communication_weight",
        //        "score": "-"
        //    },
        //    {
        //        "name": "interpersonal_relationship_weight",
        //        "score": "-"
        //    },
        //    {
        //        "name": "goal_and_achievement_striving_weight",
        //        "score": "-"
        //    }
        //],
        weighting: [].concat(...map(sections, section => {
            return map(categories[section], category => ({
                name: `${category.toLowerCase()}_weight`,
                score: '-',
            }))
        })),
    },
    usage: [],
    loadingRecruiterProfile: false,
    loadingStatistics: false,
    changingCandidateStatus: false,
    loadingReport: false,
    exportingVideo: false,
    sendingEmail: false,
    updatingInformation: false,
    updatingCandidateInformation: false,
    changingCandidateDeadline: false,
    gettingRemainingQuota: false,
    setingRedo: false,
    stringFilter: '',
    typeFilter: 'auto_score',
    percentageFilter: null,
    page: 0,
    rowsPerPage: 10,
    changingPassword: false,
    gettingUsage: false,
    fetchingPurchases: false,
    purchases: null,
    fetchingPurchaseUsages: false,
    purchaseUsages: null,
    fetchingPurchaseBalance: false,
    purchaseBalance: null,
    creatingRecruiterAccInProgress: false,
    resetPasswordInProgress: false,
    sendingForgetPasswordEmailInProgress: false,
    invitingGuestReviewer: false,
    loadingJobInfo: false,
    jobTitleForGuest: '',
    departmentNameForGuest: '',
    redoingQuestions: false,
    changeDeadlineCompletion: 0,
    groups:[
        {
            "gid":"testgid1",
            "role":"Topadmin"
        },
        {
            "gid":"testgid2",
            "role":"admin"
        },
        {
            "gid":"testgid3",
            "role":"recruiter"
        },
    ],
    passwordPolicy: {
        "passlen": 12,
        "strength": {
            "upcase": true,
            "lowcase": true,
            "number": true,
            "symbol": true
        },
        "expire": 0,
        "adminResetExpire": false,
        "selfChangePass": true,
        "preventReuse": true
    },
}

let columnName = [
    "Job id",
    "Number of candidate",
    "Deadline",
    "Number of question",
    "Invitation coverage",
    "Reminder coverage",
    "Percentage of completion",
    "Average score",
    "Skewness",
    "Standard deviation",
]

let columnName2 = [
    "Interview Id",
    "Department Name",
    "Job Name",
    "First Video At",
    "Last Video At",
    "Number of Video Uploaded"
]

function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const recruiterSlice = createSlice({
    slice: 'recruiter',
    initialState: initialState,
    reducers: {
        onLoadingJobInfoFailed(state, action) { },
        onLoadingRecruiterProfile(state, action) {
            state.loadingRecruiterProfile = true;
        },
        onLoadingRecruiterProfileSuccess(state, action) {
            const profile = action.payload;
            state.firstname = profile.firstname;
            state.lastname = profile.lastname;
            state.activation = profile.activation;
            state.roles = profile.roles
            state.parse_cv_function = profile.parse_cv_function
            state.loadingRecruiterProfile = false;
            state.logo_pic = profile.logo_pic
            state.ai_required = profile.ai_required
            state.manual_review_required = profile.manual_review_required
            state.sessionDuration = profile.sessionDuration
            state.passwordPolicy = profile.passwordPolicy
            state.recruiterId = profile.recruiterId
        },
        onLoadingRecruiterProfileFailed(state, action) {
            state.loadingRecruiterProfile = false;
        },

        onLoadingStatistics(state, action) {
            state.loadingStatistics = true;
        },
        onLoadingStatisticsSuccess(state, action) {
            state.loadingStatistics = false;
            state.currentViewingStatistics = action.payload;
        },
        onLoadingStatisticsFailed(state, action) {
            state.loadingStatistics = false;
            state.currentViewingStatistics = action.payload.statistics;
        },

        onCreatingRecruiterAcc(state, action) {
            state.creatingRecruiterAccInProgress = true
        },
        onCreatingRecruiterAccSuccess(state, action) {
            state.creatingRecruiterAccInProgress = false
        },
        onCreatingRecruiterAccFailed(state, action) {
            state.creatingRecruiterAccInProgress = false
        },

        onLoadingReport(state, action) {
            state.loadingStatistics = true;
        },
        onLoadingReportSuccess(state, action) {
            state.loadingStatistics = false;
            state.currentViewingReport = action.payload
        },
        onLoadingReportFailed(state, action) {
            state.loadingStatistics = false;
        },

        onSendingEmail(state, action) {
            // state.sendingEmail = true
        },
        onSendingEmailSuccess(state, action) {
            // state.sendingEmail = false
        },
        onSendingEmailFailed(state, action) {
            // state.sendingEmail = false
        },

        onExportingVideo(state, action) {
            state.exportingVideo = true;
        },
        onExportingVideoSuccess(state, action) {
            state.exportingVideo = false;
        },
        onExportingVideoFailed(state, action) {
            state.exportingVideo = false;
        },

        onSetRedo(state, action) {
            state.setingRedo = true
        },

        onSetRedoSuccess(state, action) {
            state.setingRedo = false
        },

        onSetRedoFailed(state, action) {
            state.setingRedo = false
        },
        onGettingRemainingQuota(state, action) {
            state.gettingRemainingQuota = true
        },
        onGettingRemainingQuotaSuccess(state, action) {
            state.gettingRemainingQuota = false
            state.isOutOfQuota = action.payload.length === 0
        },
        onGettingRemainingQuotaFailed(state, action) {
            state.gettingRemainingQuota = false
        },
        onChangingCandidateStatus(state, action) {
            state.changingCandidateStatus = true
        },
        onChangingCandidateStatusSuccess(state, action) {
            state.changingCandidateStatus = false
        },
        onChangingCandidateStatusFailed(state, action) {
            state.changingCandidateStatus = false
        },
        onUpdatingInformation(state, action) {
            state.updatingInformation = true
        },
        onUpdatingInformationSuccess(state, action) {
            state.updatingInformation = false
        },
        onUpdatingInformationFailed(state, action) {
            state.updatingInformation = false
        },
        onUpdatingCandidateInformation(state, action) {
            state.updatingCandidateInformation = true
        },
        onUpdatingCandidateInformationSuccess(state, action) {
            state.updatingCandidateInformation = false
        },
        onUpdatingCandidateInformationFailed(state, action) {
            state.updatingCandidateInformation = false
        },
        onChangingCandidateDeadline(state, action) {
            state.changingCandidateDeadline = true
            state.changeDeadlineCompletion = 0
        },
        onChangingCandidateDeadlineSuccess(state, action) {
            console.log(action.payload.changeDeadlineCompletion)
            state.changeDeadlineCompletion = action.payload.changeDeadlineCompletion
            state.changingCandidateDeadline = action.payload.changingCandidateDeadline
        },
        onInitializechangeDeadlineCompletion(state, action) {
            state.changeDeadlineCompletion = 0
        },
        onChangingCandidateDeadlineFailed(state, action) {
            state.changingCandidateDeadline = false
        },
        setFilter(state, action) {
            state.statFilter = action.payload;
        },
        setStatusFilter(state, action) {
            state.statusFilter = action.payload;
        },
        setStringFilter(state, action) {
            state.stringFilter = action.payload
        },
        setTypeFilter(state, action) {
            state.typeFilter = action.payload
        },
        setPercentageFilter(state, action) {
            state.percentageFilter = action.payload
        },
        setPage(state, action) {
            state.page = action.payload
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload
        },
        onChangingPassword(state, action) {
            state.changingPassword = true
        },
        onChangingPasswordSuccess(state, action) {
            state.changingPassword = false
        },
        onChangingPasswordFailed(state, action) {
            state.changingPassword = false
        },
        onGettingUsage(state, action) {
            state.gettingUsage = true
        },
        onGettingUsageSuccess(state, action) {
            state.gettingUsage = false
            state.usage = action.payload
        },
        onGettingUsageFailed(state, action) {
            state.gettingUsage = false
        },

        onFetchingPurchases(state) {
            state.fetchingPurchases = true
        },
        onFetchingPurchasesSuccess(state, action) {
            state.fetchingPurchases = false
            state.purchases = action.payload
        },
        onFetchingPurchasesFailed(state, action) {
            state.fetchingPurchases = false
        },

        onFetchingPurchaseUsages(state, action) {
            state.fetchingPurchaseUsages = true
        },
        onFetchingPurchaseUsagesSuccess(state, action) {
            state.fetchingPurchaseUsages = false
            state.purchaseUsages = action.payload
        },
        onFetchingPurchaseUsagesFailed(state, action) {
            state.fetchingPurchaseUsages = false
        },

        onFetchingPurchaseBalance(state, action) {
            state.fetchingPurchaseBalance = true
        },
        onFetchingPurchaseBalanceSuccess(state, action) {
            state.fetchingPurchaseBalance = false
            state.purchaseBalance = action.payload
        },
        onFetchingPurchaseBalanceFailed(state, action) {
            state.fetchingPurchaseBalance = false
        },
        onResetPassword(state, action) {
            state.resetPasswordInProgress = true
        },
        onResetPasswordSuccess(state, action) {
            state.resetPasswordInProgress = false
        },
        onResetPasswordFailed(state, action) {
            state.resetPasswordInProgress = false
        },
        onSendingForgetPasswordEmail(state, action) {
            state.sendingForgetPasswordEmailInProgress = true
        },
        onSendingForgetPasswordEmailSuccess(state, action) {
            state.sendingForgetPasswordEmailInProgress = false
        },
        onSendingForgetPasswordEmailFailed(state, action) {
            state.sendingForgetPasswordEmailInProgress = false
        },
        onInvitingGuestReviewer(state, action) {
            state.invitingGuestReviewer = true
        },
        onInvitingGuestReviewerSuccess(state, action) {
            state.invitingGuestReviewer = false
        },
        onInvitingGuestReviewerFailed(state, action) {
            state.invitingGuestReviewer = false
        },
        onLoadingJobInfo(state, action) {
            state.loadingJobInfo = true
        },
        onLoadingJobInfoSuccess(state, action) {
            state.loadingJobInfo = false
            state.jobTitleForGuest = action.payload.jobTitle
            state.departmentNameForGuest = action.payload.departmentName
        },
        onLoadingJobInfo(state, action) {
            state.loadingJobInfo = false
        },
        onRedoingQuestions(state, action) {
            state.redoingQuestions = true
        },
        onRedoingQuestionsSuccess(state, action) {
            state.redoingQuestions = false
        },
        onRedoingQuestionsFailed(state, action) {
            state.redoingQuestions = false
        },        
        onUploadingImage(state, action) {
            state.onUploadingImage = true
        },
        onUploadingimageSuccess(state, action) {
            state.onUploadingImage = false
        },
        onUploadingimageFailed(state, action) {
            state.onUploadingImage = false
        },
        onUploadingCV(state, action) {
            state.onUploadingCV = true
        },
        onUploadingCVSuccess(state, action) {
            state.onUploadingCV = false
        },
        onUploadingCVFailed(state, action) {
            state.onUploadingCV = false
        },
        onFetchingOngoingInterview(state, action) {
            state.fetchingOngoingInterview = true
        },
        onFetchingOngoingInterviewSuccess(state, action) {
            state.fetchingOngoingInterview = false
        },
        onFetchingOngoingInterviewFailed(state, action) {
            state.fetchingOngoingInterview = false
        },

        exportStat(state, action) {
            const filename = `statistics_${new Date().valueOf()}.csv`
            var csvContent = "data:text/csv;charset=utf-8,";
            csvContent += columnName.join(",");
            csvContent += "\r\n";
            csvContent += action.payload.job_id + "," + action.payload.numOfCandidates + "," + action.payload.deadline + "," + action.payload.questionNum + "," + action.payload.invitation + "%," + action.payload.reminder + "%," + action.payload.completion + "%," + action.payload.average + "," + action.payload.stdev + "," + action.payload.skewness;
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        },
        exportReview(state, action) {
            var filename = ''
            if (action.payload.length === 1) {
                filename = `${action.payload.interviewId}_review.csv`
            } else {
                filename = `${new Date().valueOf()}_review.csv`
            }
            var csvContent = "data:text/csv;charset=utf-8,";
            csvContent += columnName2.join(",");
            for (var row of action.payload) {
                csvContent += "\r\n";
                csvContent += row.interviewId + "," + row.programName + "," + row.jobName + "," + row.firstInterview + "," + row.lastInterview + "," + row.numOfVideo;
            }
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        },
        clearReport(state, action) {
            state.currentViewingReport = {
                email: "",
                //score: {
                //    "communication": "-",
                //    "composure": "-",
                //    "creativity_innovation": "-",
                //    "emotional_intelligence": "-",
                //    "enthusiasm": "-",
                //    "eye_contact": "-",
                //    "goal_achievement": "-",
                //    "impression_score": "-",
                //    "impression_weight": "-",
                //    "interpersonal_relationship": "-",
                //    "leadership": "-",
                //    "learning_ability": "-",
                //    "overall_score": "-",
                //    "pace": "-",
                //    "potential_work_score": "-",
                //    "potential_work_weight": "-",
                //    "self_confidence": "-",
                //    "speed_of_speech": "-"
                //},
                score: reduce([].concat(...map(sections, section => {
                    return map(categories[section], category => `${category.toLowerCase()}`)
                })), (acc, x) => Object.assign(acc, {
                    [x]: '-'
                }), {}),
                //weighting: [{
                //        "name": "enthusiasm_weight",
                //        "score": "-"
                //    },
                //    {
                //        "name": "self_confidence_weight",
                //        "score": "-"
                //    },
                //    {
                //        "name": "composure_weight",
                //        "score": "-"
                //    },
                //    {
                //        "name": "pace_weight",
                //        "score": "-"
                //    },
                //    {
                //        "name": "leadership_weight",
                //        "score": "-"
                //    },
                //    {
                //        "name": "learning_agility_weight",
                //        "score": "-"
                //    },
                //    {
                //        "name": "creativity_and_innovation_weight",
                //        "score": "-"
                //    },
                //    {
                //        "name": "emotional_intelligence_weight",
                //        "score": "-"
                //    },
                //    {
                //        "name": "communication_weight",
                //        "score": "-"
                //    },
                //    {
                //        "name": "interpersonal_relationship_weight",
                //        "score": "-"
                //    },
                //    {
                //        "name": "goal_and_achievement_striving_weight",
                //        "score": "-"
                //    }
                //],
                weighting: [].concat(...map(sections, section => {
                    return map(categories[section], category => ({
                        name: `${category.toLowerCase()}_weight`,
                        score: '-',
                    }))
                })),
            };
        }
    }
})

export const createSubRecruiter = (token, recruiter_info) => async dispatch => {
    try {
        dispatch(onCreatingRecruiterAcc())
        const res = await register(token, recruiter_info.email, recruiter_info.firstName, recruiter_info.lastName, recruiter_info.password, 1, 1)
        if (res.ok && res.obj) {
            if (res.obj.error_code === 0) {
                console.debug(res.obj)
                dispatch(onCreatingRecruiterAccSuccess())
            } else {
                console.log("Error on response");
                dispatch(onCreatingRecruiterAccFailed());
            }
            return res.obj.error_code
        } else {
            console.log("Error on Network");
            dispatch(onCreatingRecruiterAccFailed());
        }
    } catch (err) {
        dispatch(onCreatingRecruiterAccFailed({
            message: err.toString()
        }));
        return {
            message: "Login Error"
        };
    }
}

/**
 * 
 * @param {String} token 
 */
export const fetchRecruiterProfile = (token) => async dispatch => {
    dispatch(onLoadingRecruiterProfile());
    try {
        const res = await fetchStatus(token);
        const profile = {};
        // console.log(`fetchStatus:`,res.obj.result)
        if (res.ok) {
            const initialResponse = res.obj;
            const { result: response } = initialResponse
            if (initialResponse.error_code === 0) {
                profile.firstname = response.first_name;
                profile.lastname = response.last_name;
                profile.activation = response.activation;
                profile.recruiterId = response.recruiter_id
                profile.roles = response.roles
                profile.logo_pic = response.logo_pic
                profile.download_video = response.download_video
                profile.parse_cv_function = response.parse_cv_function
                profile.ai_required = response.ai_required
                profile.manual_review_required = response.manual_review_required
                profile.sessionDuration = "session_timeout_in_sec" in response ? 
                    response.session_timeout_in_sec : SESSION_CONSTANTS.INACTIVE_TIMEOUT_IN_SECOND
                profile.passwordPolicy = response.passwordPolicy
                dispatch(onLoadingRecruiterProfileSuccess(profile));
                console.log("Profile: ", profile)
                return profile;
            } else {
                console.log("Error with error code")
                dispatch(onLoadingRecruiterProfileFailed({
                    message: "Error with error code"
                }));
            }
        } else {
            console.log("Error with api call")
            dispatch(onLoadingRecruiterProfileFailed({
                message: "Error with error code"
            }));
        }
    } catch (err) {
        console.log(err);
        dispatch(onLoadingRecruiterProfileFailed({
            message: err.toString()
        }));
        return false;
    }
}

export const setCandidateRedo = (job_id, interview_ids, token) => async dispatch => {
    dispatch(onSetRedo());
    try {
        // console.log("wrwsegrasfewr")
        const res = await setRedo(job_id, interview_ids, token);
        if (res.ok && res.obj.error_code === 0) {
            dispatch(onSetRedoSuccess());
            console.log(res.obj.message)
            return true
        } else {
            console.log("Error with api call (ok)")
            dispatch(onSetRedoFailed({
                message: "Error with error code (ok)"
            }));
            return false
        }
    } catch (err) {
        console.log(err);
        dispatch(onSetRedoFailed({
            message: err.toString()
        }));
        return false;
    }
}

export const changeRecruiterPassword = (existing_password, new_password, token) => async dispatch => {
    dispatch(onChangingPassword());
    try {
        const res = await changePassword(existing_password, new_password, token);
        if (res.ok && res.obj.error_code === 0) {
            dispatch(onChangingPasswordSuccess());
            console.log(res.obj.message)
            return true
        } else {
            console.log("Error with api call (ok)")
            dispatch(onChangingPasswordFailed({
                message: "Error with error code (ok)"
            }));
            return false
        }
    } catch (err) {
        console.log(err);
        dispatch(onChangingPasswordFailed({
            message: err.toString()
        }));
        return false;
    }
}

export const updateRecruiterInformation = (token, dic) => async dispatch => {
    dispatch(onUpdatingInformation());
    try {
        const res = await updateAccountInformation(token, dic);
        if (res.ok && res.obj.error_code === 0) {
            dispatch(onUpdatingInformationSuccess());
            dispatch(fetchRecruiterProfile(token))
            console.log(res.obj.message)
            return true
        } else {
            console.log("Error with api call (ok)")
            dispatch(onUpdatingInformationFailed({
                message: "Error with error code (ok)"
            }));
            return false
        }
    } catch (err) {
        console.log(err);
        dispatch(onUpdatingInformationFailed({
            message: err.toString()
        }));
        return false;
    }
}

export const updateRecruiterPassPolicy = (token, dic) => async dispatch => {
    dispatch(onUpdatingInformation());
    try {
        const res = await updateAccountPassPolicy(token, dic);
        if (res.ok && res.obj.error_code === 0) {
            dispatch(onUpdatingInformationSuccess());
            dispatch(fetchRecruiterProfile(token))
            console.log(res.obj.message)
            return true
        } else {
            console.log("Error with api call (ok)")
            dispatch(onUpdatingInformationFailed({
                message: "Error with error code (ok)"
            }));
            return false
        }
    } catch (err) {
        console.log(err);
        dispatch(onUpdatingInformationFailed({
            message: err.toString()
        }));
        return false;
    }
}

export const verify_sap_account = (token, dic) => async dispatch => {
    if (typeof(dic['sapPassword']) == "object") return false
    try {
        const res = await verify_sap_account_api(token, dic);
        return res.obj
    } catch (err) {
        console.error(err);
        return false;
    }
}

export const updateAnnotation = (token, dic) => async dispatch => {
    dispatch(onUpdatingInformation());
    try {
        const res = await updateAnnotationApi(token, dic);
        if (res.ok && res.obj.error_code === 0) {
            dispatch(onUpdatingInformationSuccess());
            console.log(res.obj.message)
            return true
        } else {
            console.log("Error with api call (ok)")
            dispatch(onUpdatingInformationFailed({
                message: "Error with error code (ok)"
            }));
            return false
        }
    } catch (err) {
        console.log(err);
        dispatch(onUpdatingInformationFailed({
            message: err.toString()
        }));
        return false;
    }
}

export const changeStatus = (token, jobId, interview_ids, isShortlist) => async dispatch => {
    dispatch(onChangingCandidateStatus())
    try {
        const res = await changeCandidateStatus(token, jobId, interview_ids, isShortlist)
        if (res.ok) {
            if (res.obj.error_code === 0) {
                dispatch(onChangingCandidateStatusSuccess());
                console.log(res.obj.message)
                return true
            } else {
                console.log("Error with error code (error_code)")
                dispatch(onChangingCandidateStatusFailed({
                    message: "Error with error code (error_code)"
                }));
                return false
            }
        } else {
            console.log("Error with api call (ok)")
            dispatch(onChangingCandidateStatusFailed({
                message: "Error with error code (ok)"
            }));
            return false
        }
    } catch (err) {
        console.log(err);
        dispatch(onChangingCandidateStatusFailed({
            message: err.toString()
        }));
        return false;
    }
}

export const getAccountUsage = (token) => async dispatch => {
    dispatch(onGettingUsage())
    try {
        const res = await getUsage(token);
        if (res.ok) {
            if (res.obj.error_code === 0) {
                dispatch(onGettingUsageSuccess(res.obj.result))
                return true
            } else {
                console.log("Error with error code (error_code)")
                dispatch(onGettingUsageFailed({
                    message: "Error with error code (error_code)"
                }));
                return false
            }
        } else {
            console.log("Error with api call (ok)")
            dispatch(onGettingUsageFailed({
                message: "Error with error code (ok)"
            }));
            return false
        }
    } catch (err) {
        console.log(err);
        dispatch(onGettingUsageFailed({
            message: err.toString()
        }));
        return false;
    }
}

/**
 * 
 * @param {String} [actionType] "invitation", "reminder", "confirmation"
 * @param {String} job_id 
 * @param {[String]} candidate_emails 
 * @param {String} token 
 */
export const sendEmail = (actionType, job_id, interview_ids, token) => async dispatch => {
    dispatch(onSendingEmail());
    try {
        const res = await action(actionType, job_id, interview_ids, token);
        if (res.ok) {
            if (res.obj.error_code === 0) {
                dispatch(onSendingEmailSuccess());
                
                return res.obj.message
            }else if (res.obj.error_code == 4 || res.obj.error_code == 5 ){
                dispatch(onSendingEmailFailed({
                    message: "Error with error code (error_code)"
                }));
                return res.obj.error_code
            } else {
                console.log("Error with error code (error_code)")
                dispatch(onSendingEmailFailed({
                    message: "Error with error code (error_code)"
                }));
                return false
            }
        } else {
            console.log("Error with api call (ok)")
            dispatch(onSendingEmailFailed({
                message: "Error with error code (ok)"
            }));
            return false
        }
    } catch (err) {
        console.log(err);
        dispatch(onSendingEmailFailed({
            message: err.toString()
        }));
        return false;
    }
}
export const sapStatusChange = (newStatus, job_id, interviewIds, token) => async dispatch => {
    try {
        const res = await sapStatusChangeAPI(newStatus,job_id, interviewIds, token);
        return res.obj
    } catch (err) {
        console.log("error",err);
        return false;
    }
}

export const sapStatusReset = (job_id, candidate_emails, token) => async dispatch => {
    try {
        const res = await sapStatusResetAPI(job_id, candidate_emails, token);
        return res.obj
    } catch (err) {
        console.log("error",err);
        return false;
    }
}
/**
 * 
 * @param {String} [actionType] "invitation", "reminder", "confirmation"
 * @param {String} job_id 
 * @param {[String]} candidate_emails 
 * @param {String} token 
 */
export const sendReportEmail = (lang, job_id, interview_ids, report_type, token) => async dispatch => {
    dispatch(onSendingEmail());
    try {
        const res = await reportAction(lang, job_id, interview_ids, report_type, token);
        if (res.ok) {
            if (res.obj.error_code === 0) {
                dispatch(onSendingEmailSuccess());
                console.log(res.obj.message)
                return res.obj
            } else {
                dispatch(onSendingEmailFailed({
                    message: "Error with error code (error_code)"
                }));
                return false
            }
        } else {
            dispatch(onSendingEmailFailed({
                message: "Error with error code (ok)"
            }));
            return false
        }
    } catch (err) {
        console.log(err);
        dispatch(onSendingEmailFailed({
            message: err.toString()
        }));
        return false;
    }
}


/**
 * 
 * @param {String} [job_id] 
 * @param {String} [score_filter] "auto_score"
 * @param {String} token 
 */
export const fetchStatistics = (job_id, score_filter, token) => async dispatch => {
    dispatch(onLoadingStatistics());
    var statistics = {};
    statistics.completedCandidates = 0
    statistics.numOfCandidates = 0
    statistics.numOfDayLeft = '-';
    statistics.deadline = "-";
    statistics.invitation = 0;
    statistics.reminder = 0;
    statistics.confirmation = 0;
    statistics.average = '-';
    statistics.skewness = '-';
    statistics.stdev = '-';
    statistics.frequencyDistribute = [{
        "count": 0,
        "range": 1
    },
    {
        "count": 0,
        "range": 2
    },
    {
        "count": 0,
        "range": 3
    },
    {
        "count": 0,
        "range": 4
    },
    {
        "count": 0,
        "range": 5
    },
    {
        "count": 0,
        "range": 6
    },
    {
        "count": 0,
        "range": 7
    },
    {
        "count": 0,
        "range": 8
    },
    {
        "count": 0,
        "range": 9
    },
    {
        "count": 0,
        "range": 10
    }
    ];
    statistics.questionNum = '-';
    try {
        const res = await getStat(job_id, score_filter, token);

        if (res.ok && res.obj && res.obj.error_code === 0) {
            const result = res.obj.result;
            statistics.completedCandidates = result.completed_candidates;
            statistics.numOfCandidates = result.total_num;
            statistics.deadline = result.deadline.split(" ")[0] || '-';
            if (result.deadline) {
                const date1 = new Date(result.deadline.replace(/-/g, "/"));
                const date2 = new Date();
                const diffTime = date1 - date2;
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                statistics.numOfDayLeft = diffDays > 0 ? diffDays : 'Recruiter_Stat_Deadline_Expired';
            } else {
                statistics.numOfDayLeft = '-'
            }


            statistics.invitation = Math.round(result.invitation * 100) || 0;
            statistics.reminder = Math.round(result.reminder * 100) || 0;
            statistics.confirmation = Math.round(result.confirmation * 100) || 0;
            statistics.average = round(result.average, 1) || '-';
            statistics.skewness = round(result.skewness, 1) || '-';
            statistics.stdev = round(result.stdev, 1) || '-';
            statistics.frequencyDistribute = result.frequency_distribution;
            statistics.questionNum = result.num_of_question || '-';
            dispatch(onLoadingStatisticsSuccess(statistics));
            return statistics;
        } else {
            console.log("Error with api call (getStat)")
            dispatch(onLoadingStatisticsFailed({
                statistics: statistics
            }));
        }
    } catch (err) {
        console.log(err);
        dispatch(onLoadingStatisticsFailed({
            message: err.toString(),
            statistics: statistics
        }));
        return false;
    }
}

/**
 * 
 * @param {String} email 
 * @param {String} job_id
 */
export const updateCandidate = (token, interview_id, new_info) => async dispatch => {
    dispatch(onUpdatingCandidateInformation())
    try {
        let res = await updateCandidateInformation(token, interview_id, new_info)
        if (res.ok) {
            let response = res.obj
            console.log(response)
            if (response.error_code === 0) {
                dispatch(onUpdatingCandidateInformationSuccess())
                return true
            } else {
                console.log("Error with error code")
                dispatch(onUpdatingCandidateInformationFailed({
                    message: "Error with error code"
                }));
                return false
            }
        } else {
            console.log("Error with api call")
            dispatch(onUpdatingCandidateInformationFailed({
                message: "Error with error code"
            }));
            return false
        }

    } catch (err) {
        console.log(err);
        dispatch(onUpdatingCandidateInformationFailed({
            message: err.toString()
        }));
        return false;
    }
}
export const initializeCandidateDeadline = () => async dispatch => {
    dispatch(onInitializechangeDeadlineCompletion())
}
export const updateCandidateDeadline = (token, job_id, interview_ids, interviewEnd, sendNotice, sendInvitation) => async dispatch => {
    dispatch(onChangingCandidateDeadline())
    try {
        if (interview_ids.length === 1) {
            let res = await changeCandidateDeadline(token, interviewEnd, job_id, interview_ids, sendNotice, sendInvitation)
            if (res.ok) {
                let response = res.obj
                if (response.error_code === 0) {
                    dispatch(onChangingCandidateDeadlineSuccess({
                        changeDeadlineCompletion: 100,
                        changingCandidateDeadline: false
                    }))
                    return true
                } else {
                    console.log("Error with error code")
                    dispatch(onChangingCandidateDeadlineFailed({
                        message: "Error with error code"
                    }));
                    return false
                }
            } else {
                console.log("Error with api call")
                dispatch(onChangingCandidateDeadlineFailed({
                    message: "Error with error code"
                }));
                return false
            }
        } else {
            var completeCount = 0
            for (let count = 0; count < interview_ids.length; count = count + 5) {
                console.log(count)
                let res = await changeCandidateDeadline(token, interviewEnd, job_id, interview_ids.slice(count, count + 5), sendNotice, sendInvitation)
                if (res.ok) {
                    let response = res.obj
                    console.log(response)
                    if (response.error_code === 0) {
                        completeCount = completeCount + 5
                        dispatch(onChangingCandidateDeadlineSuccess({
                            changeDeadlineCompletion: ((count + 5) / interview_ids.length * 100 > 100) ? 100 : ((count + 5) / interview_ids.length * 100),
                            changingCandidateDeadline: true
                        }))
                    } else {
                        console.log("Error with error code")
                        dispatch(onChangingCandidateDeadlineFailed({
                            message: "Error with error code"
                        }));
                        return false
                    }
                } else {
                    console.log("Error with api call")
                    dispatch(onChangingCandidateDeadlineFailed({
                        message: "Error with error code"
                    }));
                    return false
                }
            }
            dispatch(onChangingCandidateDeadlineSuccess({
                changeDeadlineCompletion: 100,
                changingCandidateDeadline: false
            }))
            return true
        }
    } catch (err) {
        console.log(err);
        dispatch(onChangingCandidateDeadlineFailed({
            message: err.toString()
        }));
        return false;
    }
}

export const sendForgetPasswordEmail = (email) => async dispatch => {
    dispatch(onSendingForgetPasswordEmail())
    try {
        let res = await forgetPassword(email)
        if (res.ok) {
            let response = res.obj
            console.log(response)
            if (response.error_code === 0) {
                dispatch(onSendingForgetPasswordEmailSuccess())
            } else {
                console.log("Error with error code")
                dispatch(onSendingForgetPasswordEmailFailed({
                    message: "Error with error code"
                }));
            }
            return response.error_code
        } else {
            console.log("Error with api call")
            dispatch(onSendingForgetPasswordEmailFailed({
                message: "Error with error code"
            }));
        }
        return 9
    } catch (err) {
        console.log(err);
        dispatch(onSendingForgetPasswordEmailFailed({
            message: err.toString()
        }));
        return 9;
    }
}

export const resetRecruiterPassword = (password, token) => async dispatch => {
    dispatch(onResetPassword())
    try {
        let res = await resetPassword(password, token)
        if (res.ok) {
            let response = res.obj
            console.log(response)
            if (response.error_code === 0) {
                dispatch(onResetPasswordSuccess())
            } else {
                console.log("Error with error code")
                dispatch(onResetPasswordFailed({
                    message: "Error with error code"
                }));
            }
            return response.error_code
        } else {
            console.log("Error with api call")
            dispatch(onResetPasswordFailed({
                message: "Error with error code"
            }));
        }
        return 9
    } catch (err) {
        console.log(err);
        dispatch(onResetPasswordFailed({
            message: err.toString()
        }));
        return 9
    }
}

/**
 * 
 * @param {String} email 
 * @param {String} job_id
 */
export const fetchReport = (interview_id, token) => async dispatch => {
    dispatch(onLoadingReport())
    try {
        let res = await getReport(interview_id, token)
        if (res.ok) {
            let response = res.obj
            if (response.error_code !== 9) {
                let report = {}
                log.info(response)
                report.email = response.email
                report.job_id = response.job_id
                report.beauty = response.beauty
                report.glasses = response.glasses
                report.score = response.scoring_metrics
                report.weighting = response.weighting_matrics ? response.weighting_matrics : [{
                    "name": "enthusiasm_weight",
                    "score": "-"
                },
                {
                    "name": "self_confidence_weight",
                    "score": "-"
                },
                {
                    "name": "composure_weight",
                    "score": "-"
                },
                {
                    "name": "pace_weight",
                    "score": "-"
                },
                {
                    "name": "leadership_weight",
                    "score": "-"
                },
                {
                    "name": "learning_agility_weight",
                    "score": "-"
                },
                {
                    "name": "creativity_and_innovation_weight",
                    "score": "-"
                },
                {
                    "name": "emotional_intelligence_weight",
                    "score": "-"
                },
                {
                    "name": "communication_weight",
                    "score": "-"
                },
                {
                    "name": "interpersonal_relationship_weight",
                    "score": "-"
                },
                {
                    "name": "goal_and_achievement_striving_weight",
                    "score": "-"
                }
                ]
                report.score = Object.assign(
                    {}, report.score,
                    /**
                     * Decreasing order of the selection of the requisite score to display
                     * When manual_review_required == 1,
                     *  select 'manual_scoring_metrics'
                     * When manual_review_required == 0,
                     *  select scoring_metrics
                     */
                    get(response, 'manual_scoring_metrics',
                        get(response, 'reviewer_score', 
                            response.scoring_metrics
                        )
                    )
                )
                report.reviewerScoreList = response.recruiter_scorings ? response.recruiter_scorings : []
                dispatch(onLoadingReportSuccess(report))

            } else {
                console.log("Error with error code")
                dispatch(onLoadingReportFailed({
                    message: "Error with error code"
                }));
            }
        } else {
            console.log("Error with api call")
            dispatch(onLoadingReportFailed({
                message: "Error with error code"
            }));
        }
    } catch (err) {
        console.log(err);
        dispatch(onLoadingReportFailed({
            message: err.toString()
        }));
        return false;
    }
}

/**
 * 
 * @param {String} email 
 * @param {String} job_id
 */
export const fetchReportByGuest = (token) => async dispatch => {
    dispatch(onLoadingReport())
    try {
        let res = await getReportByGuest(token)

        if (res.ok) {
            let response = res.obj.report

            if (res.obj.error_code !== 9) {
                // console.log(response)
                let report = {}
                report.jobId = response.job_id
                report.beauty = response.beauty
                report.glasses = response.glasses
                report.score = response.scoring_metrics
                report.weighting = response.weighting_matrics ? response.weighting_matrics : [{
                    "name": "enthusiasm_weight",
                    "score": "-"
                },
                {
                    "name": "self_confidence_weight",
                    "score": "-"
                },
                {
                    "name": "composure_weight",
                    "score": "-"
                },
                {
                    "name": "pace_weight",
                    "score": "-"
                },
                {
                    "name": "leadership_weight",
                    "score": "-"
                },
                {
                    "name": "learning_agility_weight",
                    "score": "-"
                },
                {
                    "name": "creativity_and_innovation_weight",
                    "score": "-"
                },
                {
                    "name": "emotional_intelligence_weight",
                    "score": "-"
                },
                {
                    "name": "communication_weight",
                    "score": "-"
                },
                {
                    "name": "interpersonal_relationship_weight",
                    "score": "-"
                },
                {
                    "name": "goal_and_achievement_striving_weight",
                    "score": "-"
                }
                ]
                report.score = Object.assign({}, report.score, get(response, 'manual_scoring_metrics', response.scoring_metrics))
                report.reviewerScoreList = response.recruiter_scorings ? response.recruiter_scorings : []

                dispatch(onLoadingReportSuccess(report))
            } else {
                console.log("Error with error code")
                dispatch(onLoadingReportFailed({
                    message: "Error with error code"
                }));
            }
        } else {
            console.log("Error with api call")
            dispatch(onLoadingReportFailed({
                message: "Error with error code"
            }));
        }
    } catch (err) {
        console.log(err);
        dispatch(onLoadingReportFailed({
            message: err.toString()
        }));
        return false;
    }
}

export const getJobInfoByGuest = (token) => async dispatch => {
    dispatch(onLoadingJobInfo())
    try {
        let res = await fetchJobInfoByGuest(token)
        if (res.ok) {
            let response = res.obj
            if (response.error_code !== 9) {
                const payload = {
                    jobTitle: response.job.job_name,
                    departmentName: response.program_name,
                }
                dispatch(onLoadingJobInfoSuccess(payload))
            } else {
                console.log("Error with error code")
                dispatch(onLoadingJobInfoFailed({
                    message: "Error with error code"
                }));
            }
        } else {
            console.log("Error with api call")
            dispatch(onLoadingJobInfoFailed({
                message: "Error with error code"
            }));
        }
    } catch (err) {
        console.log(err);
        dispatch(onLoadingJobInfoFailed({
            message: err.toString()
        }));
        return false;
    }
}

/**
 * 
 * @param {String} token 
 * @param {[String]} filename 
 */
export const exportVideoZip = (token, filename) => async dispatch => {
    dispatch(onExportingVideo())
    try {
        var taskComplete = false
        var retry = 0
        while (!taskComplete) {
            const res = await zipVideo(token, filename)
            if (res.ok) {
                const response = res.obj
                if (response.error_code === 0) {
                    await sleep(1000)
                    const checkingRes = await checkTaskComplete(response.message)
                    if (checkingRes.ok) {
                        if (checkingRes.obj.task_result === "ok") {
                            const videoZip = await exportVideo(response.filename)
                            var a = document.createElement('a');
                            var url = window.URL.createObjectURL(videoZip.data);
                            a.href = url;
                            a.download = response.filename;
                            a.click();
                            window.URL.revokeObjectURL(url);
                            taskComplete = true
                        }
                    }
                    retry++
                    if (retry === 5) {
                        dispatch(onExportingVideoFailed())
                        break;
                    }
                    dispatch(onExportingVideoSuccess())
                }
            }
        }
    } catch (err) {
        console.log(err);
        dispatch(onExportingVideoFailed({
            message: err.toString()
        }));
        return false;
    }
}

export const fetchExportedFilesList = (token) => async dispatch => {
    try {
        const res = await fetchExportedFiles(token)
        console.log("files", res)
        if (!res.ok){
            return []
        }
        const response = res.obj;
        if (response.error_code === 0) {
            return response.data
        }

    } catch (err) {
        console.log(err);
    }
}
export const getIfOverUsage = (token) => async dispatch => {
    dispatch(onGettingRemainingQuota());
    try {
        // console.log("wrwsegrasfewr")
        const res = await getRemainingQuota(token);
        if (res.ok && res.obj.error_code === 0) {
            dispatch(onGettingRemainingQuotaSuccess(res.obj.result));
        } else {
            console.log("Error with api call (ok)")
            dispatch(onGettingRemainingQuotaFailed({
                message: "Error with error code (ok)"
            }));
        }
    } catch (err) {
        console.log(err);
        dispatch(onGettingRemainingQuotaFailed({
            message: err.toString()
        }));
    }
}

/**
 * @param {String} token
 */
export const fetchPurchases = (token, start = null, limit = null) => async dispatch => {
    dispatch(onFetchingPurchases())
    let ret = false
    try {
        const res = await getPurchases(token, start, limit)
        if (res.ok) {
            if (res.obj.error_code === 0) {
                dispatch(onFetchingPurchasesSuccess(res.obj.result))
                ret = true
            } else {
                console.log(`Error with error code (${res.obj.error_code})`)
                dispatch(onFetchingPurchasesFailed({
                    message: `Error with error code (${res.obj.error_code})`,
                }))
            }
        } else {
            console.log("Error with api call (ok)")
            dispatch(onFetchingPurchasesFailed({
                message: 'Error with api call (ok)',
            }))
        }
    } catch (err) {
        console.log(err)
        dispatch(onFetchingPurchasesFailed({
            message: err.toString(),
        }))
    }
    return ret
}

/**
 * @param {String} token
 */
export const fetchPurchaseUsages = (token, start = null, limit = null) => async dispatch => {
    dispatch(onFetchingPurchaseUsages())
    let ret = false
    try {
        const res = await getPurchaseUsages(token, start, limit)
        if (res.ok) {
            if (res.obj.error_code === 0) {
                dispatch(onFetchingPurchaseUsagesSuccess(res.obj.result))
                ret = true
            } else {
                console.log(`Error with error code (${res.obj.error_code})`)
                dispatch(onFetchingPurchaseUsagesFailed({
                    message: `Error with error code (${res.obj.error_code})`,
                }))
            }
        } else {
            console.log("Error with api call (ok)")
            dispatch(onFetchingPurchaseUsagesFailed({
                message: 'Error with api call (ok)',
            }))
        }
    } catch (err) {
        console.log(err)
        dispatch(onFetchingPurchaseUsagesFailed({
            message: err.toString(),
        }))
    }
    return ret
}

/**
 * @param {String} token
 */
export const fetchPurchaseBalance = (token, group) => async dispatch => {
    dispatch(onFetchingPurchaseBalance())
    let ret = false
    try {
        const res = await getPurchaseBalance(token,group)
        if (res.ok) {
            if (res.obj.error_code === 0) {
                dispatch(onFetchingPurchaseBalanceSuccess(res.obj.result))
                ret = true
            } else {
                console.log(`Error with error code (${res.obj.error_code})`)
                dispatch(onFetchingPurchaseBalanceFailed({
                    message: `Error with error code (${res.obj.error_code})`,
                }))
            }
        } else {
            console.log("Error with api call (ok)")
            dispatch(onFetchingPurchaseBalanceFailed({
                message: 'Error with error code (ok)',
            }))
        }
    } catch (err) {
        console.log(err)
        dispatch(onFetchingPurchaseBalanceFailed({
            message: err.toString(),
        }))
    }
    return ret
}

export const inviteGuestReviewer = (token, candidates, reviewers, message, job_id) => async dispatch => {
    dispatch(onInvitingGuestReviewer())
    try {
        const res = await inviteEvaluation(token, candidates, reviewers, message, job_id)
        if (res.ok) {
            if (res.obj.error_code === 0) {
                dispatch(onInvitingGuestReviewerSuccess())
                return 0
            } else {
                console.log(`Error with error code (${res.obj.error_code})`)
                dispatch(onInvitingGuestReviewerFailed({
                    message: `Error with error code (${res.obj.error_code})`,
                }))
                return res.obj.error_code
            }
        } else {
            console.log("Error with api call (ok)")
            dispatch(onInvitingGuestReviewerFailed({
                message: 'Error with error code (ok)',
            }))
        }
    } catch (err) {
        console.log(err)
        dispatch(onInvitingGuestReviewerFailed({
            message: err.toString(),
        }))
    }
    return 9
}

export const redoQuestions = (token, jobId, interviewId, questions, needEmail) => async dispatch => {
    dispatch(onRedoingQuestions())
    let ret = false
    try {
        const res = await redoQuestionsApi(token, jobId, interviewId, questions, needEmail)
        if (res.ok) {
            if (res.obj.error_code === 0) {
                dispatch(onRedoingQuestionsSuccess())
                ret = true
            } else {
                console.log(`Error with error code (${res.obj.error_code})`)
                dispatch(onRedoingQuestionsFailed({
                    message: `Error with error code (${res.obj.error_code})`,
                }))
            }
        } else {
            console.log("Error with api call (ok)")
            dispatch(onRedoingQuestionsFailed({
                message: 'Error with error code (ok)',
            }))
        }
    } catch (err) {
        console.log(err)
        dispatch(onRedoingQuestionsFailed({
            message: err.toString(),
        }))
    }
    return ret
}

export const uploadCandidateCV = (token, value, type) => async dispatch => {
    dispatch(onUploadingCV())
    const ret = false

    /**
     * Switch between different parsing strategies for the file name as the import_by_json &
     * import_by_cv features have different object formats
     */
    let name;
    if(type == 0){
        name = `resumes/${value.email.trim().toLowerCase().replaceAll("@", "").replaceAll(".", "") + "_" + value.jobId + value.cvExtension}`
    } else if(type == 2){
        name = `resumes/${value.basicInfo.Email.trim().toLowerCase().replaceAll("@", "").replaceAll(".", "") + "_" + value.jobId + value.cvExtension}`
    }

    try {
        //get the presigned URL
        //const res = await create_s3_upload_url_simple("neufast-resumes", name, token, value.cv.type)
        const res = await create_s3_upload_url_simple(BUCKETS.RESUME_BUCKET, name, token, value.cv.type)
        //upload to S3
        if (res.errors === undefined) {
            const formData = new FormData()
            Object.entries(res.fields).forEach(([key, values]) => {
                formData.append(key, values);
            })
            formData.append('Content-Type', value.cv.type)
            formData.append('file', value.cv)
            try {
                const response = await axios.post(res.url, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                return response
            } catch (err) {
                console.log(err)
                dispatch(onUploadingCVFailed({
                    message: err.toString(),
                }))
            }
        } else {
            console.log("Error with api call")
            dispatch(onUploadingCVFailed({
                message: 'Error with error code ',
            }))
        }
    } catch (err) {
        console.log(err)
    }
}
export const fetchOngoingInterview = token => async dispatch => {
    dispatch(onFetchingOngoingInterview())
    let ret = false
    try {
        const res = await fetchOngoingInterviewApi(token)
        if (res.ok) {
            if (res.obj.error_code === 0) {
                dispatch(onFetchingOngoingInterviewSuccess())
                ret = res.obj.result
            } else {
                console.log(`Error with error code (${res.obj.error_code})`)
                dispatch(onFetchingOngoingInterviewFailed({
                    message: `Error with error code (${res.obj.error_code})`,
                }))
            }
        } else {
            console.log("Error with api call (ok")
            dispatch(onFetchingOngoingInterviewFailed({
                message: "Error with error code (ok)",
            }))
        }
    } catch (err) {
        console.log(err)
        dispatch(onFetchingOngoingInterviewFailed({
            message: err.toString(),
        }))
    }
    return ret
}

export const settingUploadImage = (token, value, bucket_name, pathName) => async dispatch => {
    dispatch(onUploadingImage())
    const ret = false
    console.log(value)
    try{
        //get the presigned URL
        const res = await create_s3_upload_url_simple(bucket_name, pathName + value.filename, token, value.fileType)
        //upload to S3
        if (res.errors === undefined) {
            const formData = new FormData()
            Object.entries(res.fields).forEach(([key, values]) => {
                formData.append(key,values);
            })
            formData.append('Content-Type', value.fileType)
            formData.append('file', value.file)
            try{
                const response = await axios.post(res.url, formData, {
                    headers: {'Content-Type': 'multipart/form-data'},
                })
                return response
            } catch (err) {
                console.log(err)
                dispatch(onUploadingImageFailed({
                    message: err.toString(),
                }))
            }
        } else {
            console.log("Error with api call")
            dispatch(onUploadingImageFailed({
                message: 'Error with error code',
            }))
        }
    } catch (err) {
        console.log(err)
        dispatch(onUploadingImageFailed({
            message: err.toString(),
        }))
    }
    return ret
}

export const multiPDFAndZip = async (token,pdfUrl) =>{
    let zip = new JSZip();
    let folder = zip.folder('PDF_Reports');

    var filename,zipContent = [];
    if (!pdfUrl.length) return
    pdfUrl.forEach((ele, i) => {
        zipContent.push(
            fetch(ele.report).then(async response => {
                const rep = await response.blob()
                // filename = response.url.split("?")[0].split("/")
                filename = `${ele.name}.pdf`
                const imageFile = new File([rep], filename);
                folder.file(`${filename}`, imageFile);
            }))
    })
    await Promise.all(zipContent);
    await folder.generateAsync({ type: "blob" }).then(content => saveAs(content, "PDF_Report"));
}


export const {
    onLoadingRecruiterProfile,
    onLoadingRecruiterProfileSuccess,
    onLoadingRecruiterProfileFailed,
    onLoadingReport,
    onLoadingReportSuccess,
    onLoadingReportFailed,
    onLoadingStatistics,
    onLoadingStatisticsSuccess,
    onLoadingStatisticsFailed,
    onSendingEmail,
    onSendingEmailSuccess,
    onSendingEmailFailed,
    onExportingVideo,
    onExportingVideoSuccess,
    onExportingVideoFailed,
    onSetRedo,
    onSetRedoSuccess,
    onSetRedoFailed,
    onChangingCandidateStatus,
    onChangingCandidateStatusSuccess,
    onChangingCandidateStatusFailed,
    onUpdatingCandidateInformation,
    onUpdatingCandidateInformationSuccess,
    onUpdatingCandidateInformationFailed,
    onInitializechangeDeadlineCompletion,
    onChangingCandidateDeadline,
    onChangingCandidateDeadlineSuccess,
    onChangingCandidateDeadlinePartialSuccess,
    onChangingCandidateDeadlineFailed,
    setFilter,
    exportStat,
    exportReview,
    clearReport,
    setStatusFilter,
    setStringFilter,
    setTypeFilter,
    setPercentageFilter,
    setPage,
    setRowsPerPage,
    onGettingUsage,
    onGettingUsageSuccess,
    onGettingUsageFailed,
    onUpdatingInformation,
    onUpdatingInformationSuccess,
    onUpdatingInformationFailed,
    onChangingPassword,
    onChangingPasswordSuccess,
    onChangingPasswordFailed,
    onFetchingPurchases,
    onFetchingPurchasesSuccess,
    onFetchingPurchasesFailed,
    onFetchingPurchaseUsages,
    onFetchingPurchaseUsagesSuccess,
    onFetchingPurchaseUsagesFailed,
    onFetchingPurchaseBalance,
    onFetchingPurchaseBalanceSuccess,
    onFetchingPurchaseBalanceFailed,
    onCreatingRecruiterAcc,
    onCreatingRecruiterAccSuccess,
    onCreatingRecruiterAccFailed,
    onGettingRemainingQuota,
    onGettingRemainingQuotaSuccess,
    onGettingRemainingQuotaFailed,
    onResetPassword,
    onResetPasswordSuccess,
    onResetPasswordFailed,
    onSendingForgetPasswordEmail,
    onSendingForgetPasswordEmailSuccess,
    onSendingForgetPasswordEmailFailed,
    onInvitingGuestReviewer,
    onInvitingGuestReviewerSuccess,
    onInvitingGuestReviewerFailed,
    onLoadingJobInfo,
    onLoadingJobInfoSuccess,
    onLoadingJobInfoFailed,
    onRedoingQuestions,
    onRedoingQuestionsSuccess,
    onRedoingQuestionsFailed,
    onUploadingCV,
    onUploadingCVSuccess,
    onUploadingCVFailed,
    onFetchingOngoingInterview,
    onFetchingOngoingInterviewSuccess,
    onFetchingOngoingInterviewFailed,
    onUploadingImage,
    onUploadingImageSuccess,
    onUploadingImageFailed,
} = recruiterSlice.actions;

export default recruiterSlice.reducer;