import { create_s3_upload_url_simple, gets3object, createS3UploadPresignedURL } from '../apis/s3access'
import axios from 'axios'


export const upload_single_object_to_s3 = async (bucket_name, obj, object_name, token, content_type = null) => {
        const res = await create_s3_upload_url_simple(bucket_name, obj, object_name, token, content_type)
        if (res.obj?.error == 2) 
            throw new Error("Invalid File")
        const formData = new FormData()
        Object.entries(res.obj.fields).forEach(([key, values]) => {
            formData.append(key, values);
        })

        formData.append('file', obj, object_name)
        try {
            const response = await axios.post(res.obj.url, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return response
        } catch (err) {
            console.log(err)
        }
}





export const get_interviewerImage = async (object_name, token, bucket_name) => {
    const res = await gets3object(object_name, token, bucket_name)
    return res
}


export const fetchUploadPresignedUrl = async (token, bucket_name, object_name) => {
    try {
        const getUrlPayload = {
            token: token,
            bucket_name: bucket_name,
            object_name: object_name,
        }
        const presignedResponse = await createS3UploadPresignedURL(getUrlPayload)

        if (presignedResponse?.body.error_code != 0) {
            throw new Error("Error in getting presigned url: " + presignedResponse?.body)
        }

        const put_url = presignedResponse?.body.presigned_url

        return put_url

    }catch(err){
        console.error(err);
        throw err;
    }
}


