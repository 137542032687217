import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import Footer from './components/footer/Footer';
import { ThemeProvider } from '@material-ui/styles';
import NeufastTheme from "./theme/NeufastTheme";
import { makeStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import ProviderSetup from "./ProviderSetup";
import configureNeufastStore from "./store/configureNeufastStore";
import Home from "./pages/RecruiterHome";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import {sessionService} from 'redux-react-session'
import _ from 'lodash'
import {checkSessionToken} from './apis/session'


const store = configureNeufastStore();

const validateSession = async (session) => {
  if (_.isEmpty(session)) {
    return true
  } else {
    const response = await checkSessionToken(session.user.interviewId, session.user.token)
    if ("body" in response) {
      const content = response.body
      console.log("content inside the validationSession: ", content)
      return content.error_code !== 0
    } else {
      console.log("jump into false block")
      return false
    }
  }
}


const options = {
  refreshOnCheckAuth: true,
  redirectPath: '/',
  driver: 'INDEXEDDB',
  validateSession
}

sessionService.initSessionService(store, options)
  .then(() => console.log("Redux React Session is ready!"))
  .catch(() => console.log("Redux React Session has error"))

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(0),
    padding: theme.spacing(0),
  },
}));

const mapState = state => {
  return {
    colorScheme: state.preference.colorScheme,
  }
};

const Recruiter = lazy(() => import("./pages/Recruiter"))
const Candidate = lazy(() => import("./pages/Candidate"))
// const CandidateAutoLogin = lazy(() => import("./pages/CandidateAutoLogin"))
const Admin = lazy(() => import("./pages/Admin"))
const ResetPassword = lazy(() => import("./components/reset-password/ResetPassword"))
const GuestReview = lazy(() => import("./components/candidate-result/GuestReview"))
const RecruiterRegistration = lazy(() => import("./components/groups/RecruiterRegistration"))
const SsoLogin = lazy(() => import("./pages/SsoLogin"))

const Routes = () => {
  const classes = useStyles()
  const location = useLocation()

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: location.pathname,
      page_path: location.pathname
    })
  }, [location])

  useEffect(() => {
    console.log("sessionService: ", sessionService)
  }, [])

  return (
    <Box component="main" className={classes.main}>
      <Switch>
        <Route path="/recruiter" component={Recruiter} />
        <Route path="/candidate" component={Candidate} />
        <Route path="/sso/:urlHash" component={SsoLogin} />
        <Route path="/admin" component={Admin} />
        <Route path="/reset-password/:role/:token" component={ResetPassword} />
        <Route path="/guest/review/:token/:interviewLanguage" component={GuestReview} />
        <Route path="/register/:token" component={RecruiterRegistration} />
        <Route path="/" component={Home} />
      </Switch>
    </Box>
  )
}

const AppTheme = connect(mapState)(({ colorScheme }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={NeufastTheme(colorScheme)}>
      <div className={classes.root}>
        <CssBaseline />
        <Suspense fallback={null}>
          <Router>
            <Routes />
          </Router>
        </Suspense>
        {/* <Footer /> */}
      </div>
    </ThemeProvider>
  )
});

function App() {
  const classes = useStyles();

  return (
    <ProviderSetup store={store}>
      <AppTheme />
    </ProviderSetup>
  );
}

export default App;
