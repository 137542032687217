import { assign } from 'lodash'

var config = {
    baseUrl: 'http://localhost:8082',
    languages: [ 'en', 'tc', 'sc' ],
    interviewLanguages: [ 'english', 'cantonese', 'mandarin' ],
}

try {
  if( process.env.NODE_ENV === 'development' ) {
    const devConfig = require( './config.dev.js' ).default
    config = assign( config, devConfig )
  } else {
    const prodConfig = require( './config.prod.js' ).default
    config = assign( config, prodConfig )
  }
} catch( e ) {
  console.debug( e )
}

export default config

