import clientRequest from "../constants/clientRequest"

const tag = 'api/statistics';

export const exportData = (candidate_interview_id, export_keys, job_id, token, selectJob, filename) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_expore_data({
                payload: {
                    candidate_interview_id,
                    export_keys,
                    job_id,
                    token,
                    selectJob,
                    filename,
                }
            });
        })
};

export const zipVideo = (token, video_filenames) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_export_all_videos({
                payload: {
                    token,
                    video_filenames,
                }
            });
        })
};

export const exportVideo = (zip_filename) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].get_get_all_videos({
                filename: zip_filename
            });
        })
};

export const checkTaskComplete = async (task_id) => {
    return clientRequest.then(client => {
        return client.apis[tag].get_get_video_task({
            task_id: task_id
        })
    })
}

export const getStat = (job_id, score_filter, token) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_statistics({
                payload: {
                    job_id,
                    score_filter,
                    token,
                }
            });
        })
};

export const getAllStat = (token) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_fetch_all_statistics({
                payload: {
                    token,
                }
            });
        })
};

export const fetchExportedFiles = (token) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_fetch_exported_files({
                payload: {
                    token,
                }
            });
        })
}
const statistics = {
    exportData,
    zipVideo,
    exportVideo,
    checkTaskComplete,
    getStat,
    fetchExportedFiles,
};

export default statistics;
